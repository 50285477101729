@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

$tooltip-padding-x: 9px;
$tooltip-padding-y: 6px;
$tooltip-font-size: $size-12;
$tooltip-bg: $black;
$tooltip-opacity: .9;
$tooltip-border-radius: 8px;
$tooltip-margin: 5px;
.tooltip {
    --#{$prefix}tooltip-zindex: #{$zindex-tooltip};
  --#{$prefix}tooltip-max-width: #{$tooltip-max-width};
  --#{$prefix}tooltip-padding-x: #{$tooltip-padding-x};
  --#{$prefix}tooltip-padding-y: #{$tooltip-padding-y};
  --#{$prefix}tooltip-margin: #{$tooltip-margin};
  @include rfs($tooltip-font-size, --#{$prefix}tooltip-font-size);
  --#{$prefix}tooltip-color: #{$tooltip-color};
  --#{$prefix}tooltip-bg: #{$tooltip-bg};
  --#{$prefix}tooltip-border-radius: #{$tooltip-border-radius};
  --#{$prefix}tooltip-opacity: #{$tooltip-opacity};
  --#{$prefix}tooltip-arrow-width: #{$tooltip-arrow-width};
  --#{$prefix}tooltip-arrow-height: #{$tooltip-arrow-height};

  .tooltip-inner {
      line-height: 19px;
      font: {
        family: 'IBM Plex Sans Arabic', sans-serif;
        weight: $medium;
    }
  }
}