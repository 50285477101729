@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.news-section {
    .article-link {
        margin-bottom: 10px;
    }
}
article {
    cursor: pointer;
    position: relative;
    .article-link {
        text-decoration: none;
        display: block;
        padding: 20px 32px;
        border: 1px solid transparent;
        border-radius: $radius-10;
        position: relative;
        width: 100%;
        cursor: pointer;
        @include transition-all;
        @include media-breakpoint-down(md) {
            padding: 20px 0;
            border-bottom: 1px solid var(--gray-200);
            border-radius: 0;
        }
        .auther-publish {
            @include media-breakpoint-down(lg) {
                display: flex;
                align-items: center;
                gap: 16px;
                margin-bottom: 16px;
            }
            .display-text {
                margin-top: 14px;
                @include media-breakpoint-down(lg) {
                    margin-top: 0;
                }
                span {
                    font-size: $size-12;
                    color: var(--gray-700);
                    display: block;
                    margin-bottom: 3px;
                }
                h3 {
                    font-size: $size-13;
                    font-weight: $medium;
                    color: var(--dark);
                }
            }
        }
        .wrapper-article {
            display: flex;
            align-items: center;
            gap: 20px;
            @include media-breakpoint-down(md) {
                display: grid;
            }
        }
        .article_cover {
            img {
                width: 188px;
                height: 140px;
                border-radius: $radius-10;
                object-fit: cover;
            }
        }
        .article-details-text {
            .date {
                font-size: $size-12;
                color: var(--gray-700);
            }
            .title {
                font-size: $size-15;
                font-weight: $medium;
                color: var(--dark);
                margin: 8px 0;
            }
            p {
                font-size: $size-12;
                color: var(--gray-700);
                margin: 0;
                width: calc(100% - 50px);
            }
        }
        &:hover {
            border-color: var(--gray-200);
            @include shadow-default;
        }
    }
    .btn-like {
        position: absolute;
        left: 20px;
        top: 20px;
        padding: 10px;
        z-index: 2;
    }
}

.btn-like {
    display: flex;
    align-items: center;
    border: 0;
    padding: 0;
    flex-direction: row-reverse;
    gap: 8px;
    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        i {
            font-size: 20px;
            &::before {
                @include transition-all;
            }
        }
    }
    span {
        font-size: $size-14;
        color: var(--gray-700);
        font-weight: $medium;
        @include font-en;
        @include transition-all;
    }
    &.active {
        .iconsax {
            &::before {
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.44 3.09961C14.63 3.09961 13.01 3.97961 12 5.32961C10.99 3.97961 9.37 3.09961 7.56 3.09961C4.49 3.09961 2 5.59961 2 8.68961C2 9.87961 2.19 10.9796 2.52 11.9996C4.1 16.9996 8.97 19.9896 11.38 20.8096C11.72 20.9296 12.28 20.9296 12.62 20.8096C15.03 19.9896 19.9 16.9996 21.48 11.9996C21.81 10.9796 22 9.87961 22 8.68961C22 5.59961 19.51 3.09961 16.44 3.09961Z' fill='%23EA494E'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-size: 20px;
                width: 20px;
                height: 20px;
            }
        }
        span {
            color: var(--primary);
        }
    }
}

.modal-article {
    .modal-dialog {
        @include media-breakpoint-up(lg) {
            max-width: 584px;
        }
        .modal-content {
            .modal-header {
                padding: 30px 40px;
                gap: 24px;
                border: 0;
                .modal-title {
                    font-size: $size-22;
                    font-weight: $semiBold;
                    color: var(--dark);
                    @include media-breakpoint-down(sm) {
                        font-size: $size-16;
                    }
                }
            }
            .modal-body {
                padding: 0 40px 30px 40px;
                .article__cover {
                    margin-bottom: 24px;
                    img {
                        width: 100%;
                        height: 300px;
                        object-fit: cover;
                        border-radius: $radius-10;
                    }
                }
                .date_pub {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    margin-bottom: 18px;
                    .iconsax {
                        font-size: 14px;
                        color: var(--gray-700);
                    }
                    span {
                        color: var(--gray-700);
                        font-size: $size-12;
                        display: inline-block;
                    }
                }
                .description {
                    p {
                        font-size: $size-15;
                        color: var(--gray-800);
                    }
                }
            }
            .modal-footer {
                border-top: 1px solid var(--gray-200);
                padding: 32px 40px;
                justify-content: flex-start;
                .pubish_user {
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    .display-txt {
                        span {
                            font-size: $size-12;
                            color: var(--gray-700);
                            display: block;
                            margin-bottom: 2px;
                        }
                        p {
                            font-size: $size-12;
                            font-weight: $medium;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
    .btn__close {
        position: absolute;
        right: -70px;
        top: 40px;
        width: 40px;
        height: 40px;
        border-radius: $radius-circle;
        border: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        background-color: rgba($dark, 0.4);
        transform: scale(0.75);
        transition: 0.4s cubic-bezier(0.65, 0.05, 0.36, 1) 0.4s;
        opacity: 0;
        i {
            background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.85197 13.1578C5.63542 13.1578 5.41886 13.0781 5.2479 12.9071C4.91737 12.5766 4.91737 12.0295 5.2479 11.699L11.699 5.2479C12.0295 4.91737 12.5766 4.91737 12.9071 5.2479C13.2376 5.57843 13.2376 6.12551 12.9071 6.45605L6.45605 12.9071C6.29648 13.0781 6.06853 13.1578 5.85197 13.1578Z' fill='white'/%3E%3Cpath d='M12.303 13.1578C12.0865 13.1578 11.8699 13.0781 11.699 12.9071L5.2479 6.45605C4.91737 6.12551 4.91737 5.57843 5.2479 5.2479C5.57843 4.91737 6.12551 4.91737 6.45605 5.2479L12.9071 11.699C13.2376 12.0295 13.2376 12.5766 12.9071 12.9071C12.7361 13.0781 12.5196 13.1578 12.303 13.1578Z' fill='%23F9F9FA'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: 20px;
            width: 20px;
            height: 20px;
        }
        @include media-breakpoint-down(sm) {
            right: inherit;
            top: inherit;
            left: 30px;
            bottom: 30px;
        }
    }
    &.show {
        .btn__close {
            opacity: 1;
            transform: scale(1);
        }
    }
}
