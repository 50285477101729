@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

$accordion-bg: $white;
$accordion-border-color: var(--gray-200);
$accordion-border-radius: $radius-10;
$accordion-inner-border-radius: $radius-10;
$accordion-button-padding-x: 20px;
$accordion-button-padding-y: 16px;
$accordion-color: var(--dark);
$accordion-button-active-color: var(--dark);
$accordion-button-active-bg: var(--white);
$accordion-body-padding-x: 20px;
$accordion-body-padding-y: 16px;

.accordion {
    --#{$prefix}accordion-color: #{color-contrast($accordion-bg)};
    --#{$prefix}accordion-bg: #{$accordion-bg};
    --#{$prefix}accordion-transition: #{$accordion-transition};
    --#{$prefix}accordion-border-color: #{$accordion-border-color};
    --#{$prefix}accordion-border-width: #{$accordion-border-width};
    --#{$prefix}accordion-border-radius: #{$accordion-border-radius};
    --#{$prefix}accordion-inner-border-radius: #{$accordion-inner-border-radius};
    --#{$prefix}accordion-btn-padding-x: #{$accordion-button-padding-x};
    --#{$prefix}accordion-btn-padding-y: #{$accordion-button-padding-y};
    --#{$prefix}accordion-btn-color: #{$accordion-color};
    --#{$prefix}accordion-btn-bg: #{$accordion-button-bg};
    --#{$prefix}accordion-btn-icon: #{escape-svg($accordion-button-icon)};
    --#{$prefix}accordion-btn-icon-width: #{$accordion-icon-width};
    --#{$prefix}accordion-btn-icon-transform: #{$accordion-icon-transform};
    --#{$prefix}accordion-btn-icon-transition: #{$accordion-icon-transition};
    --#{$prefix}accordion-btn-active-icon: #{escape-svg($accordion-button-active-icon)};
    --#{$prefix}accordion-btn-focus-border-color: #{$accordion-button-focus-border-color};
    --#{$prefix}accordion-btn-focus-box-shadow: #{$accordion-button-focus-box-shadow};
    --#{$prefix}accordion-body-padding-x: #{$accordion-body-padding-x};
    --#{$prefix}accordion-body-padding-y: #{$accordion-body-padding-y};
    --#{$prefix}accordion-active-color: #{$accordion-button-active-color};
    --#{$prefix}accordion-active-bg: #{$accordion-button-active-bg};
}

.accordion {
    .accordion-item {
        .accordion-header {
            .accordion-button {
                font-size: $size-14;
                font-weight: $medium;
                transition: background-color, 0.4s all;
                &:hover {
                    background-color: var(--light);
                }
                &::after {
                    margin-left: inherit;
                    margin-right: auto;
                    font-family: remixicon !important;
                    content: "\EA50";
                    font-size: 18px;
                    color: var(--gray-600);
                    background: none;
                    display: flex;
                    justify-content: center;
                }
            }
        }
        .accordion-body {
            font-size: $size-14;
            color: var(--gray-700);
            font-weight: $normal;
            line-height: normal;
        }
    }
}
