@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

/*
    @FILE: DEFAULT STYLE
    @AUTHER: HASAN SIBAKHI
    @URL: https://dribbble.com/HasanSib

    THIS FILE CONTAINS THE STYLING FOR THE ACTUAL TEMPLATE, THIS
    IS THE FILE YOU NEED TO EDIT TO CHANGE THE LOOK OF THE
    TEMPLATE.

*/
html,
body {
    direction: rtl;
    height: 100%;
}

::selection {
    background-color: #000;
    color: $white;
}

body {
    position: relative;
    overflow-y: auto !important;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--white);
    transition: background-color,.4s all;
    padding-right: 0 !important;
    font: {
        family: "IBM Plex Sans Arabic", sans-serif;
        size: $size-14;
        weight: $normal;
    }
    color: var(--dark);
    &.modal-open {
        overflow-y: auto !important;
        padding-right: 0 !important;
        .tc-side_menu {
            padding-right: 0 !important;
        }
    }
    &.bg-snow {
        background-color: var(--light);
    }
}

button,
input,
textarea,
label,
a {
    outline: 0 !important;
    box-shadow: none !important;
}

img {
    user-select: none;
}

i {
    display: inline-block;
}

svg {
    width: 24px;
    height: 24px;
}

code {
    padding: 4px 10px;
    border-radius: 8px;
    background-color: var(--gray-200);
    color: var(--gray-800);
    font-size: $size-14;
    font-weight: $medium;
    display: inline-block;
}

.simplebar-track {
    left: 5px !important;
    right: inherit !important;
    &.simplebar-vertical {
        width: 10px !important;
    }
}
.simplebar-scrollbar {
    &::before {
        background: var(--gray-400) !important;
    }
}

.brand-logo {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    text-decoration: none;
    img {
        width: 50px;
        height: 50px;
    }
    .text-logo {
        span {
            font-size: $size-13;
            color: var(--gray-600);
            display: block;
            margin-bottom: 1px;
        }
        p {
            font-size: $size-13;
            font-weight: $medium;
            margin: 0;
        }
    }
    &.sm {
        img {
            width: 40px;
            height: 40px;
        }
        .text-logo {
            span {
                font-size: $size-12;
            }
            p {
                font-size: $size-12;
            }
        }
    }
}

.wrapper-main-body {
    @include media-breakpoint-up(xl) {
        display: grid;
        grid-template-columns: 270px 1fr;
        grid-template-areas:
            "sidenav header"
            "sidenav main";
    }
}

.content-main {
    grid-area: main;
    padding: 30px 50px;
    @include media-breakpoint-down(lg) {
        padding: 20px;
    }
}

.dividar-icon-team {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 24px;
    .display-text {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .title-team {
        font-size: $size-14;
        font-weight: $medium;
        color: var(--dark);
        width: max-content;
    }
    .line {
        background-color: var(--gray-200);
        height: 0.01rem;
        width: 100%;
    }
    .splide__arrows {
        display: flex;
        align-items: center;
        gap: 8px;
        
        .splide__arrow {
            padding: 0;
            position: inherit;
            inset: inherit;
            border-radius: $radius-circle;
            background-color: transparent;
            border: 1px solid var(--gray-200);
            width: 34px;
            height: 34px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            transform: none;
            opacity: 1;
            @include transition-all;
            @include shadow-default;
            &::before {
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.40007 6.0707C6.28033 5.95096 6.22411 5.79841 6.22411 5.64665C6.22411 5.4949 6.28033 5.34235 6.40007 5.22261C6.63246 4.99022 7.01577 4.99022 7.24816 5.22261L9.60149 7.57594C9.83388 7.80833 9.83388 8.19165 9.60149 8.42403L7.24816 10.7774C7.01577 11.0098 6.63246 11.0098 6.40007 10.7774C6.16768 10.545 6.16768 10.1617 6.40007 9.92928L8.32936 7.99999L6.40007 6.0707Z' fill='%23162842' stroke='%23162842' stroke-width='0.2'/%3E%3C/svg%3E%0A");
                background-size: 16px;
                width: 16px;
                height: 16px;
                background-repeat: no-repeat;
            }
            &.splide__arrow--next {
                &::before {
                    transform: scale(-1,1);
                }
            }
        }
    }
}

.icon-team {
    width: 32px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: $radius-circle;
    i {
        font-size: 14px;
        &::before {
            font-weight: 600 !important;
        }
    }
    &.it {
        background-color: #FCE8E9;
        i {
            color: #891014;
        }
    }
    &.mt {
        background-color: #fcf1e8;
        i {
            color: #834011;
        }
    }
    &.pt {
        background-color: #e8eafc;
        i {
            color: #0C125F;
        }
    }
    &.bs {
        background-color: #EBFAF1;
        i {
            color: #196639;
        }
    }
}


.loader-page {
    position: fixed !important;
    width: 100%;
    height: 100%;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    z-index: 9999;

    &.hidden {
        animation: fadeOut 1s;
        animation-fill-mode: forwards;
        pointer-events: none;
    }
}