@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;


.offcanvas {
    transition: transform .8s cubic-bezier(.77,0,.18,1);
    &.showing {
        transform: none !important;
    }
    &.canvas-profile {
        width: 320px;
        @include media-breakpoint-down(sm) {
            width: 90%;
        }
        @include media-breakpoint-up(xl) {
            width: 270px !important;
            visibility: visible;
            transform: none !important;
            z-index: 50 !important;
        }
    }
    &.canvas-userProfile {
        @include media-breakpoint-down(lg) {
            width: 320px !important;
        }
        @include media-breakpoint-down(sm) {
            width: 90% !important;
        }
    }
}