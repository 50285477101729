@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

$table-border-color: var(--gray-200);
$table-color: var(--gray-800);
$table-cell-padding-x: 20px;
$table-cell-padding-y: 14px;
$table-striped-bg: var(--light);
$table-cell-vertical-align: middle;

.table {
    --#{$prefix}table-color: #{$table-color};
    --#{$prefix}table-bg: #{$table-bg};
    --#{$prefix}table-border-color: #{$table-border-color};
    --#{$prefix}table-accent-bg: #{$table-accent-bg};
    --#{$prefix}table-striped-color: #{$table-striped-color};
    --#{$prefix}table-striped-bg: #{$table-striped-bg};
    --#{$prefix}table-active-color: #{$table-active-color};
    --#{$prefix}table-active-bg: #{$table-active-bg};
    --#{$prefix}table-hover-color: #{$table-hover-color};
    --#{$prefix}table-hover-bg: #{$table-hover-bg};

    width: 100%;
    margin-bottom: $spacer;
    color: var(--#{$prefix}table-color);
    vertical-align: $table-cell-vertical-align;
    border-color: var(--#{$prefix}table-border-color);
  
    // Target th & td
    // We need the child combinator to prevent styles leaking to nested tables which doesn't have a `.table` class.
    // We use the universal selectors here to simplify the selector (else we would need 6 different selectors).
    // Another advantage is that this generates less code and makes the selector less specific making it easier to override.
    // stylelint-disable-next-line selector-max-universal
    > :not(caption) > * > * {
      padding: $table-cell-padding-y $table-cell-padding-x;
      background-color: var(--#{$prefix}table-bg);
      border-bottom-width: $table-border-width;
      box-shadow: inset 0 0 0 9999px var(--#{$prefix}table-accent-bg);
    }
    tr {
        th {
            font-size: $size-16;
            color: var(--dark);
            span {
                width: max-content;
                display: -webkit-box;
            }
        }
        td{ 
            font-size: $size-16;
            code {
                width: max-content;
                display: -webkit-box;
            }
            span {
                width: max-content;
                display: -webkit-box;
            }
        }
    }
}