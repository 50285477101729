@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;


.items-projects-teams {
    margin-top: 30px;
}
.swiper-team-projects {
    margin-bottom: 44px;
    .splide__slide {
        padding-bottom: 4px;
        background-color: transparent;
    }
    .splide__pagination {
        right: inherit;
        bottom: -24px;
        padding: 0;
        gap: 4px;
        background-color: transparent;
        .splide__pagination__page {
            background: var(--gray-300);
            transform: none;
            width: 4px;
            height: 4px;
            margin: 0;
            &.is-active {
                background-color: var(--gray-600);
            }
        }
    }
}

.item-project {
    border: 1px solid var(--gray-200);
    padding: 20px;
    border-radius: $radius-10;
    display: block;
    text-decoration: none;
    width: 100%;
    background-color: var(--white);
    @include shadow-default;
    @include transition-all;
    .head-card,
    .body-card {
        margin-bottom: 16px;
    }
    .title {
        font-size: $size-14;
        font-weight: $semiBold;
        line-height: 20px;
        color: var(--dark);
        margin-bottom: 4px;
    }
    .date {
        font-size: $size-12;
        color: var(--gray-700);
        margin: 0;
    }
    .desc {
        color: var(--gray-800);
        font-size: $size-12;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        max-height: 40px;
    }
    .tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 6px;
        span {
            padding: 6px 10px;
            border-radius: 8px;
            background-color: rgba($gray-200, 0.7);
            color: var(--gray-700);
            font-size: 10px;
            font-weight: $medium;
            display: inline-block;
        }
    }
    .icon {
        position: absolute;
        width: 30px;
        height: 30px;
        background-color: var(--light);
        border-radius: $radius-circle;
        left: 20px;
        top: 20px;
        transition: 0.4s cubic-bezier(0.79, 0.14, 0.15, 0.86);
        .icon-arrow {
            background-image: url("data:image/svg+xml,%3Csvg width='7' height='7' viewBox='0 0 7 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.62084 0.73341C5.62084 1.13439 5.28832 1.46692 4.88733 1.46692L1.47409 1.46692L1.47409 4.88017C1.47409 5.28116 1.14157 5.61368 0.740586 5.61368C0.339603 5.61368 0.00708006 5.28116 0.00708005 4.88017L0.00707986 0.73341C0.00707985 0.332427 0.339603 -9.58588e-05 0.740586 -9.58763e-05L4.88733 -9.60576e-05C5.28832 -9.60751e-05 5.62084 0.332427 5.62084 0.73341Z' fill='%235D6879'/%3E%3Cpath d='M7.0001 6.26946C7.0001 6.45528 6.93164 6.6411 6.78493 6.7878C6.50131 7.07142 6.03187 7.07142 5.74825 6.7878L0.212717 1.25227C-0.0709059 0.968648 -0.0709059 0.499207 0.212717 0.215584C0.496339 -0.0680385 0.965796 -0.0680385 1.24942 0.215584L6.78493 5.75112C6.93164 5.88804 7.0001 6.08364 7.0001 6.26946Z' fill='%235D6879'/%3E%3C/svg%3E%0A");
            background-size: 7px;
            width: 7px;
            height: 7px;
            background-repeat: no-repeat;
            transition: 0.4s all;
        }
    }
    &:hover {
        background-color: var(--light);
        box-shadow: none !important;
        .icon {
            background-color: var(--primary);
            transform: translate(5px, 5px);
            .icon-arrow {
                background-image: url("data:image/svg+xml,%3Csvg width='7' height='7' viewBox='0 0 7 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.62084 0.73341C5.62084 1.13439 5.28832 1.46692 4.88733 1.46692L1.47409 1.46692L1.47409 4.88017C1.47409 5.28116 1.14157 5.61368 0.740586 5.61368C0.339603 5.61368 0.00708006 5.28116 0.00708005 4.88017L0.00707986 0.73341C0.00707985 0.332427 0.339603 -9.58588e-05 0.740586 -9.58763e-05L4.88733 -9.60576e-05C5.28832 -9.60751e-05 5.62084 0.332427 5.62084 0.73341Z' fill='white'/%3E%3Cpath d='M7.0001 6.26946C7.0001 6.45528 6.93164 6.6411 6.78493 6.7878C6.50131 7.07142 6.03187 7.07142 5.74825 6.7878L0.212717 1.25227C-0.0709059 0.968648 -0.0709059 0.499207 0.212717 0.215584C0.496339 -0.0680385 0.965796 -0.0680385 1.24942 0.215584L6.78493 5.75112C6.93164 5.88804 7.0001 6.08364 7.0001 6.26946Z' fill='white'/%3E%3C/svg%3E%0A");
            }
        }
    }
}
