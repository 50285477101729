@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.btn-lg {
    font-size: $size-13;
    font-weight: $medium;
    border: 0;
    width: 100%;
    min-height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s, background 0.4s;

    &.bg-primary {
        &:hover {
            background-color: #e73137 !important;
        }
    }
    &:active {
        transform: scale(0.95);
    }
}

.btn-md {
    padding: 8px 16px;
    font-size: $size-13;
    font-weight: $medium;
    border-radius: 10px;
    border: 0;
    transition: transform 0.2s, background 0.4s;
    &.outline {
        border: 1px solid var(--gray-200) !important;
        color: var(--gray-700);
        @include shadow-default;
        &:hover {
            background-color: var(--light) !important;
            box-shadow: none !important;
        }
    }
    &.bg-primary {
        &:hover {
            background-color: #e73137 !important;
        }
    }
    &:active {
        transform: scale(0.95);
    }
}

.btn-sm {
    padding: 6px 12px;
    font-size: $size-12;
    font-weight: $medium;
    transition: transform 0.2s, background 0.4s, color 0.4s;
    &:active {
        transform: scale(0.95);
    }
}

.box {
    width: 34px;
    height: 34px;
    padding: 0;
    border-radius: $radius-10;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s, background 0.4s, color 0.4s;
    &:active {
        transform: scale(0.95);
    }
    img {
        width: 20px;
        height: 20px;
    }
    &.outline {
        border: 1px solid var(--gray-200);
        @include shadow-default;
    }
    &.gray {
        background-color: var(--gray-200);
    }
}

.btn_close {
    width: 30px;
    height: 30px;
    border-radius: $radius-10;
    border: 1px solid var(--gray-200) !important;
    @include shadow-default;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    i {
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.85197 13.1578C5.63542 13.1578 5.41886 13.0781 5.2479 12.9071C4.91737 12.5766 4.91737 12.0295 5.2479 11.699L11.699 5.2479C12.0295 4.91737 12.5766 4.91737 12.9071 5.2479C13.2376 5.57843 13.2376 6.12551 12.9071 6.45605L6.45605 12.9071C6.29648 13.0781 6.06853 13.1578 5.85197 13.1578Z' fill='%23808A98'/%3E%3Cpath d='M12.303 13.1578C12.0865 13.1578 11.8699 13.0781 11.699 12.9071L5.2479 6.45605C4.91737 6.12551 4.91737 5.57843 5.2479 5.2479C5.57843 4.91737 6.12551 4.91737 6.45605 5.2479L12.9071 11.699C13.2376 12.0295 13.2376 12.5766 12.9071 12.9071C12.7361 13.0781 12.5196 13.1578 12.303 13.1578Z' fill='%23808A98'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 18px;
        width: 18px;
        height: 18px;
    }
}

.btn__loadmore {
    background-color: var(--gray-200) !important;
    border-radius: $radius-10;
    padding: 8px 16px;
    border: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: .4s;
    .txt {
        font-size: $size-12;
        font-weight: $medium;
        color: var(--gray-800);
        display: flex;
        transition: .4s;
    }
    .spinner-loading {
        position: absolute;
        opacity: 0;
        transform: scale(.6);
        transition: .4s cubic-bezier(0.65,0.05,0.36,1);
    }
    &.active {
        background-color: transparent !important;
        .txt {
            opacity: 0;
        }
        .spinner-loading {
            opacity: 1;
            transform: scale(1);
        }
    }
}
