@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.modal {
    .modal-dialog {
        .modal-content {
            padding: 0;
            border: 0;
            border-radius: $radius-10;
            background-color: var(--white);
        }
    }
}

.modal-update {
    .modal-header {
        text-align: center;
        justify-content: center;
        border-bottom: 1px solid var(--gray-200);
        h5 {
            font-size: $size-15;
            font-weight: $semiBold;
            color: var(--dark);
        }
    }
    .modal-body {
        padding: 20px 40px;
        p {
            font-size: $size-14;
            color: var(--dark);
        }
        ul {
            li,ol {
                color: var(--gray-800);
            }
        }
    }
    .modal-footer {
        border-top: 1px solid var(--gray-200);
        .btn-primary {
            color: $white;
        }
    }
}