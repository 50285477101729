@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.img-thumbnail {
    border: 0;
    padding: 0;
}
.wrapper-img-thumbnail {
    border-radius: $radius-10;
    border: 1px solid var(--gray-200);
    padding: 10px;
}

.figure {
    .figure-img {
        margin-bottom: 14px;
    }
    .figure-caption {
        font-size: $size-16;
        color: var(--gray-700);
    }
}
