@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.snippet_code {
    position: relative;
    .btn-copy {
        position: absolute;
        width: 38px;
        height: 38px;
        border-radius: $radius-10;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($white, 0.1);
        font-size: $size-18;
        padding: 0;
        top: 28px;
        right: 28px;
        z-index: 3;
        @include transition-bg;
        i {
            color: $white !important;
            @include transition-color;
        }
        &:focus {
            background-color: $white;
            i {
                color: $dark !important;
            }
        }
    }
    &.isHeader {
        .head_code {
            padding: 8px 28px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: $dark;
            border-top-left-radius: $radius-10;
            border-top-right-radius: $radius-10;
            .btn-copy {
                position: relative;
                inset: inherit;
                background-color: transparent;
                &:focus {
                    i {
                        color: $white !important;
                        opacity: .5;
                    }
                }
            }
            .title {
                margin: 0;
                font-size: $size-15;
                font-weight: $medium;
                color: $gray-200;
            }
        }
        pre[class*="language-"] {
            margin: 0;
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
        }
    }
}
pre[class*="language-"] {
    direction: ltr;
    border-radius: $radius-10 !important;
    padding: 28px !important;
    background-color: #212126 !important;
}