@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.default-link {
    font-size: $size-16;
    font-weight: $normal;
    color: var(--primary);
    text-decoration: none;
    display:flex;
    align-items: center;
    gap: 10px;
    width: max-content;
    i {
        font-size: 22px;
        color: var(--dark);
    }
    &.btn {
        padding: 10px 16px;
        border-radius: 8px;
        background-color: #efebfd;
        color: var(--primary) !important;
        i {
            color: var(--dark);
        }
    }
}