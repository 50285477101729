@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.tts-wrapper-login {
    margin: 0 auto;
    padding-top: 100px;
    width: 100%;
    @include media-breakpoint-up(sm) {
        max-width: 365px;
    }
    .brand-logo {
        width: 100%;
        justify-content: center;
        margin-bottom: 32px;
    }
    .copyright {
        font-size: 11px;
        color: var(--gray-600);
        text-align: center;
        width: 100%;
        margin: 24px 0 0 0;
    }
    .theme-mode {
        display: none;
    }
    &.style-2 {
        max-width: inherit;
        margin: inherit;
        padding: 0;
        height: 100vh;
        display: grid;
        align-items: center;
    }
}

.tts-block-login {
    width: 100%;
    background-color: var(--white);
    border-radius: $radius-10;
    padding: 40px;
    @include media-breakpoint-up(md) {
        box-shadow: 0px 64px 40px 0px rgba($dark, 0.02);
    }

    .display-head-form {
        margin-bottom: 32px;
        h1 {
            font-size: $size-20;
            font-weight: $semiBold;
            color: var(--dark);
            margin-bottom: 6px;
        }
        p {
            font-size: $size-12;
            color: var(--gray-700);
            font-weight: $normal;
            margin: 0;
            span {
                color: var(--dark);
            }
        }
    }
    .form-group {
        margin-bottom: 12px;
        input {
            padding-right: 50%;
        }
    }
    &.style-2 {
        position: relative;
        height: calc(100vh - 120px);
        @include media-breakpoint-up(md) {
            width: max-content;
        }
        @include media-breakpoint-down(md) {
            height: 100vh;
            padding: 24px;
        }
        .copyright {
            display: flex;
            position: absolute;
            margin: 0;
            justify-content: center;
            text-align: center;
            left: 0;
            right: 0;
            bottom: 40px;
            direction: ltr;
        }
        .brand-logo {
            justify-content: flex-start;
            margin-bottom: 48px;
            img {
                width: 42px;
                height: 42px;
            }
            .text-logo {
                span,
                p {
                    font-size: $size-12;
                }
            }
        }
    }
}

.gradient_bg {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;

    .gradient_1 {
        position: absolute;
        left: 20%;
        top: -30%;
        width: 250px;
        height: 250px;

        background: conic-gradient(from 47.59deg at 50% 50%, #13c665 0deg, rgba(19, 198, 101, 0) 360deg);
        background-blend-mode: color-burn;
        mix-blend-mode: normal;
        filter: blur(250px);
        z-index: -1;
    }
    .gradient_2 {
        position: absolute;
        right: 50%;
        top: -30%;
        width: 250px;
        height: 250px;

        background: conic-gradient(from 43.17deg at 50% 50%, #ac30b7 0deg, rgba(172, 48, 183, 0) 360deg);
        background-blend-mode: color-burn;
        mix-blend-mode: normal;
        filter: blur(250px);
        z-index: -1;
    }
    .gradient_3 {
        position: absolute;
        right: 20%;
        top: -20%;
        width: 250px;
        height: 250px;

        background: conic-gradient(from 41.27deg at 50% 50%, #fd5722 0deg, rgba(253, 87, 34, 0) 360deg);
        background-blend-mode: color-burn;
        mix-blend-mode: normal;
        filter: blur(250px);
        z-index: -1;
    }
    &::after {
        content: "";
        background: url("../../images/texture_noise.png");
        background-repeat: repeat;
        background-size: 60px;
        inset: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        mix-blend-mode: overlay;
        opacity: 0.1;
    }
}

.image_bg {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: -1;
    img {
        animation: kenburns-bottom-left 70s ease-out both;
        width: 100%;
        height: 100%;
    }
    @include media-breakpoint-down(md) {
        opacity: 0;
    }
    &::before {
        content: "";
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 1;
        inset: 0;
        background-color: rgba($black, 0.65);
    }
}

@keyframes kenburns-bottom-left {
    0% {
        -webkit-transform: scale(1) translate(0, 0);
        transform: scale(1) translate(0, 0);
        -webkit-transform-origin: 16% 84%;
        transform-origin: 16% 84%;
    }
    100% {
        -webkit-transform: scale(1.25) translate(-20px, 15px);
        transform: scale(1.25) translate(-20px, 15px);
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
    }
}
