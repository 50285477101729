@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.tab-calendar {
    justify-content: flex-end;
    .nav-item {
        .nav-link {
            width: 38px;
            height: 34px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            border: 1px solid var(--gray-200);
            border-radius: 0;
            @include transition-all;
            .iconsax {
                font-size: 16px;
                color: var(--gray-700);
                transform: scale(-1, 1);
                &::before {
                    @include transition-all;
                }
            }
            &.active {
                background-color: var(--gray-200);
            }
        }
        &:first-child {
            .nav-link {
                border-top-right-radius: $radius-10;
                border-bottom-right-radius: $radius-10;
                &.active {
                    i {
                        &::before {
                            content: "";
                            background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.73317 9.5H13.2665C14.2665 9.5 14.6665 9.92667 14.6665 10.9867V13.68C14.6665 14.74 14.2665 15.1667 13.2665 15.1667H2.73317C1.73317 15.1667 1.33317 14.74 1.33317 13.68V10.9867C1.33317 9.92667 1.73317 9.5 2.73317 9.5Z' fill='%235D6879' stroke='%235D6879' stroke-width='0.2'/%3E%3Cpath d='M2.73317 1.8335H13.2665C14.2665 1.8335 14.6665 2.26016 14.6665 3.32016V6.0135C14.6665 7.0735 14.2665 7.50016 13.2665 7.50016H2.73317C1.73317 7.50016 1.33317 7.0735 1.33317 6.0135V3.32016C1.33317 2.26016 1.73317 1.8335 2.73317 1.8335Z' fill='%235D6879' stroke='%235D6879' stroke-width='0.2'/%3E%3C/svg%3E%0A");
                            background-repeat: no-repeat;
                            background-size: 16px;
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }
        }
        &:last-child {
            .nav-link {
                border-top-left-radius: $radius-10;
                border-bottom-left-radius: $radius-10;
                &.active {
                    i {
                        &::before {
                            content: "";
                            background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 11.3335H10C9.72667 11.3335 9.5 11.1068 9.5 10.8335C9.5 10.5602 9.72667 10.3335 10 10.3335H14C14.2733 10.3335 14.5 10.5602 14.5 10.8335C14.5 11.1068 14.2733 11.3335 14 11.3335Z' fill='%235D6879' stroke='%235D6879' stroke-width='0.2'/%3E%3Cpath d='M14 14H10C9.72667 14 9.5 13.7733 9.5 13.5C9.5 13.2267 9.72667 13 10 13H14C14.2733 13 14.5 13.2267 14.5 13.5C14.5 13.7733 14.2733 14 14 14Z' fill='%235D6879' stroke='%235D6879' stroke-width='0.2'/%3E%3Cpath d='M14.6667 6.18016V3.1535C14.6667 2.2135 14.24 1.8335 13.18 1.8335H10.4867C9.42667 1.8335 9 2.2135 9 3.1535V6.1735C9 7.12016 9.42667 7.4935 10.4867 7.4935H13.18C14.24 7.50016 14.6667 7.12016 14.6667 6.18016Z' fill='%235D6879' stroke='%235D6879' stroke-width='0.2'/%3E%3Cpath d='M6.99992 6.18016V3.1535C6.99992 2.2135 6.57325 1.8335 5.51325 1.8335H2.81992C1.75992 1.8335 1.33325 2.2135 1.33325 3.1535V6.1735C1.33325 7.12016 1.75992 7.4935 2.81992 7.4935H5.51325C6.57325 7.50016 6.99992 7.12016 6.99992 6.18016Z' fill='%235D6879' stroke='%235D6879' stroke-width='0.2'/%3E%3Cpath d='M6.99992 13.68V10.9867C6.99992 9.92667 6.57325 9.5 5.51325 9.5H2.81992C1.75992 9.5 1.33325 9.92667 1.33325 10.9867V13.68C1.33325 14.74 1.75992 15.1667 2.81992 15.1667H5.51325C6.57325 15.1667 6.99992 14.74 6.99992 13.68Z' fill='%235D6879'/%3E%3C/svg%3E%0A");
                            background-repeat: no-repeat;
                            background-size: 16px;
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }
        }
    }
}

.wrapper-list-calendar {
    .item__date-calendar {
        padding: 40px 0;
        border-bottom: 1px solid var(--gray-200);
        @include media-breakpoint-down(sm) {
            padding: 20px 0;
        }
        .content__month {
            display: flex;
            align-items: center;
            position: relative;
            @include media-breakpoint-down(md) {
                margin-bottom: 20px;
            }
            .month-item {
                @include font-en;
                width: 30px;
                .month {
                    font-size: 30px;
                    font-weight: $semiBold;
                    color: var(--dark);
                    display: block;
                    margin-bottom: 0;
                    line-height: 30px;
                }
                .year {
                    font-size: $size-16;
                    display: block;
                    font-weight: $medium;
                    color: var(--gray-700);
                }
            }
            .dividar {
                width: 1px;
                height: 46px;
                background-color: var(--gray-200);
                margin: 0 50px;
                display: block;
            }
            .display-text {
                h4 {
                    font-size: $size-13;
                    font-weight: $medium;
                    color: var(--dark);
                    margin-bottom: 4px;
                }
                p {
                    font-size: $size-11;
                    color: var(--gray-700);
                    margin: 0;
                }
            }
        }
        .item-day {
            display: grid;
            @include media-breakpoint-up(md) {
                grid-template-columns: 0.1fr 1fr;
                gap: 18px;
            }
            margin-bottom: 8px;
            .day {
                padding: 10px 0;
                @include font-en;
                font-size: $size-13;
                font-weight: $medium;
                color: var(--gray-700);
                @include media-breakpoint-up(md) {
                    text-align: left;
                }
            }
            .event-day {
                padding: 10px 16px;
                border: 1px solid #fad1d3;
                background-color: #fce8e9;
                border-radius: $radius-10;
                position: relative;
                overflow: hidden;
                span {
                    display: block;
                    position: relative;
                }
                .title {
                    font-size: $size-13;
                    font-weight: $medium;
                    color: $dark;
                    margin: 0;
                }
                .team,
                .time {
                    font-size: $size-11;
                    color: $dark;
                    margin-top: 4px;
                }
            }
            &.bg-orange {
                .event-day {
                    border-color: #f9dfcd;
                    background-color: #fcf1e8;
                }
            }
            &.bg-purple {
                .event-day {
                    border-color: #f1d0ea;
                    background-color: #f9ebf6;
                }
            }
            &.bg-blue {
                .event-day {
                    border-color: #a9aff4;
                    background-color: #e8eafc;
                }
            }
            &.bg-yellow {
                .event-day {
                    border-color: #fae9c6;
                    background-color: #fdf6e7;
                }
            }
            &.bg-green {
                .event-day {
                    border-color: #c2f0d5;
                    background-color: #ebfaf1;
                }
            }
            &.weekend {
                .event-day {
                    background-color: var(--light);
                    border-color: var(--gray-200);
                    .title {
                        color: var(--gray-700);
                    }
                    &::before {
                        content: "";
                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='38' viewBox='0 0 24 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 0H24L10 38H0L14 0Z' fill='%23F4F4F6'/%3E%3C/svg%3E%0A");
                        background-size: 26px;
                        position: absolute;
                        width: 100%;
                        height: 40px;
                        background-repeat: repeat;
                        left: 0;
                        top: 0;
                        bottom: 0;
                        z-index: 0;
                    }
                }
            }
        }
    }
}

//- CALLENDAR
.fc {
    .fc-col-header-cell-cushion {
        padding: 10px 8px !important;
        color: var(--gray-700);
        font-weight: $medium;
        font-size: $size-13;
        text-decoration: none;
    }
    .fc-scrollgrid-liquid {
        border: 0 !important;
    }
    thead,
    tfoot,
    tr,
    th {
        border-top-left-radius: $radius-10;
        border-top-right-radius: $radius-10;
    }
    tbody,
    tfoot,
    tr,
    td {
        border-bottom-left-radius: $radius-10;
        border-bottom-right-radius: $radius-10;
    }
    .fc-daygrid-day-top {
        flex-direction: row !important;
    }
    .fc-daygrid-day {
        &.fc-day-today {
            background-color: transparent !important;
            .fc-daygrid-day-number {
                background-color: var(--dark) !important;
                color: var(--white) !important;
            }
        }
    }
    .fc-daygrid-day-number {
        width: 30px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 8px;
        @include font-en;
        color: var(--gray-700);
        font-size: $size-13;
        font-weight: $medium;
        text-decoration: none;
        border-radius: $radius-circle;
    }
    .fc-toolbar {
        flex-direction: row-reverse;
        &.fc-header-toolbar {
            margin: 30px 0;
        }
    }
    .fc-toolbar-title {
        font-size: $size-16 !important;
        font-weight: $semiBold;
        color: var(--dark);
    }
    .fc-today-button {
        padding: 7px 22px !important;
        border-radius: $radius-10 !important;
        background-color: transparent !important;
        border: 1px solid var(--gray-200) !important;
        @include shadow-default;
        font-size: $size-13 !important;
        color: var(--dark) !important;
        font-weight: $medium !important;
        max-height: 34px !important;
        @include transition-all;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: 17px !important;
        &:disabled {
            background-color: var(--light) !important;
            border-color: transparent !important;
        }
    }
    .fc-toolbar-chunk {
        display: flex;
        flex-direction: row-reverse;
        gap: 16px;
    }
    .fc-button-group {
        display: flex;
        gap: 8px;
        margin: 0 !important;
    }
    .fc-prev-button,
    .fc-next-button {
        width: 34px !important;
        height: 34px !important;
        border-radius: $radius-10 !important;
        background-color: transparent !important;
        border: 1px solid var(--gray-200) !important;
        @include shadow-default;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0 !important;
        @include transition-all;
        .fc-icon {
            color: var(--dark) !important;
            font-size: 13px !important;
            font-weight: 600 !important;
            line-height: 12px !important;
        }
    }
}
.fc-daygrid-event {
    border-radius: 6px !important;
    .fc-daygrid-event-dot {
        border-color: var(--primary) !important;
    }
    .fc-event-time {
        font-weight: $semiBold !important;
        color: var(--dark) !important;
        font-size: $size-12 !important;
    }
    .fc-event-title {
        font-weight: $medium !important;
        color: var(--gray-700) !important;
        font-size: $size-11 !important;
    }
}
.fc-daygrid-dot-event {
    background-color: transparent !important;
}
.fc-h-event {
    .fc-event-title-container {
        padding: 7px 12px;
        .fc-event-title {
            font-weight: $medium !important;
            font-size: $size-12 !important;
            color:  $dark !important;
        }
    }
}

// .fc-theme-standard {
//     .fc-scrollgrid {
//         border: 1px solid var(--gray-200) !important;
//     }
// }
.fc-theme-standard {
    td {
        border: 1px solid var(--gray-200) !important;
    }
    th {
        border: 1px solid var(--gray-200) !important;
        background-color: var(--light);
    }
}

.outline-orange {
    border: 1px solid #f9dfcd;
    background-color: #fcf1e8;
}
.outline-purple {
    border: 1px solid #f1d0ea;
    background-color: #f9ebf6;
}
.outline-blue {
    border: 1px solid #a9aff4;
    background-color: #e8eafc;
}
.outline-yellow {
    border: 1px solid #fae9c6;
    background-color: #fdf6e7;
}
.outline-green {
    border: 1px solid #c2f0d5;
    background-color: #ebfaf1;
}
