@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.wrapper-statistics-teams {
    .group-info-teams {
        @include media-breakpoint-down(sm) {
            margin-top: 24px;
        }
    }
}

.item-employee-block {
    position: relative;
    border-radius: $radius-10;
    border: 1px solid var(--gray-200);
    padding: 24px 20px;
    text-align: center;
    @include shadow-default;
    @include media-breakpoint-down(lg) {
        text-align: right;
    }
    .cover {
        position: relative;
        width: 65px;
        height: 65px;
        margin-left: auto;
        margin-right: auto;
        @include media-breakpoint-down(lg) {
            width: 50px;
            height: 50px;
            margin: 0;
        }
        .user__img {
            width: 65px;
            height: 65px;
            object-fit: cover;
            border-radius: $radius-circle !important;
            @include media-breakpoint-down(lg) {
                width: 50px;
                height: 50px;
            }
        }
        .status {
            width: 9px;
            height: 9px;
            border-radius: $radius-circle;
            position: absolute;
            bottom: -1px;
            right: 9px;
            border: 2px solid var(--white);
            &.online {
                background-color: var(--green);
            }
            &.offline {
                background-color: var(--gray-500);
            }
        }
    }
    .letter-name {
        width: 65px;
        height: 65px;
        border-radius: $radius-circle;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-direction: row-reverse;
        @include media-breakpoint-down(lg) {
            width: 50px;
            height: 50px;
        }
        span {
            font-size: $size-20;
            font-weight: $semiBold;
            margin: 0 1px;
            @include font-en;
            color: $white;
            text-transform: uppercase;
        }
    }
    .display-details-user {
        margin-bottom: 24px;
        margin-top: 12px;
        @include media-breakpoint-down(lg) {
            margin: 0;
            margin-right: 16px;
        }
        .username {
            font-size: $size-14;
            font-weight: $medium;
            color: var(--dark);
            @include font-en;
            margin-bottom: 1px;
        }
        p {
            font-size: 12px;
            color: var(--gray-600);
            @include font-en;
            margin: 0;
        }
    }
    .social-apps {
        .btn {
            padding: 0;
            border: 0 !important;
            margin: 0;
            height: inherit;
            img {
                width: 22px;
                height: 22px;
                object-fit: contain;
            }
        }
    }
    .btn-details {
        margin-top: 24px;
        background-color: var(--gray-200);
        color: var(--dark);
        font-size: $size-12;
        font-weight: $medium;
        border-radius: $radius-10;
        padding: 7px 14px;
        border: 0;
        @include transition-all;
        @include media-breakpoint-down(lg) {
            margin: 0;
            margin-right: 16px;
        }
        &:hover {
            background-color: var(--primary);
            color: $white;
        }
    }
    .icon-group-team {
        display: flex;
        align-items: center;
        position: absolute;
        right: 12px;
        top: 12px;
        @include media-breakpoint-down(lg) {
            right: inherit;
            left: 20px;
            top: 32px;
        }
        .icon-teams {
            margin: 0 -4px;
        }
    }
    .icon-teams {
        width: 26px;
        height: 26px;
        border-radius: $radius-circle;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 2px solid var(--white);
        background-color: var(--light);
        .iconsax {
            font-size: 11px;
            color: var(--gray-500);
            &::before {
                font-weight: 600 !important;
            }
        }
        &.it {
            background-color: #fce8e9;
            i {
                color: #891014;
            }
        }
        &.pt {
            background-color: #e8eafc;
            i {
                color: #0c125f;
            }
        }
        &.mt {
            background-color: #fcf1e8;
            i {
                color: #834011;
            }
        }
    }
    .group-user {
        @include media-breakpoint-down(lg) {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
        }
    }
    .group-action {
        @include media-breakpoint-down(lg) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

.employee-section {
    margin-top: 30px;
    .item-employee-block {
        margin-bottom: 24px;
        @include media-breakpoint-down(lg) {
            margin-bottom: 14px;
        }
    }
}

.chart_line-team {
    background: linear-gradient(to bottom, #962c7f, #ea4750);
    border-radius: $radius-10;
    position: relative;
    overflow: hidden;
    &::before {
        content: "";
        background-image: url("../../images/texture_noise.png");
        background-position: center;
        background-repeat: repeat;
        background-size: 87px;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
        background-blend-mode: overlay;
        opacity: 0.2;
    }
    .head {
        position: relative;
        padding: 24px 24px 18px 24px;
        border-bottom: 1px solid rgba($white, 0.1);
        .title {
            font-size: $size-16;
            font-weight: $medium;
            color: $white;
            margin-bottom: 4px;
        }
        p {
            font-size: $size-11;
            color: rgba($white, 0.9);
            margin: 0;
        }
    }
    .body {
        position: relative;
        padding: 24px;
        .item-progress-team {
            margin-bottom: 14px;
            .sub_title {
                font-size: $size-12;
                font-weight: $medium;
                color: rgba($white, 0.8);
                margin-bottom: 6px;
            }
            .progress {
                background-color: transparent !important;
                border-radius: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                .progress-bar {
                    border-radius: 20px;
                    background: linear-gradient(to left, rgba($white, 0), $white);
                    display: inline-flex;
                    margin-left: 12px;
                    height: 8px;
                }
                span {
                    @include font-en;
                    font-weight: $semiBold;
                    color: $white;
                    font-size: $size-15;
                    display: inline-flex;
                }
            }
        }
    }
}

.info_text-team {
    text-align: center;
    position: relative;
    padding: 30px;
    @include media-breakpoint-down(sm) {
        padding: 12px 0;
        &::before {
            display: none;
        }
    }
    .icon {
        border-radius: $radius-circle;
        width: 36px;
        height: 36px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
        &.bg-yellow {
            background-color: $yellow;
        }
        &.bg-orange {
            background-color: $orange;
        }
        &.bg-blue {
            background-color: $blue;
        }
        &.bg-pink {
            background-color: $pink;
        }
        i {
            font-size: $size-16;
            color: $white;
        }
        
    }
    h5 {
        font-size: $size-12;
        color: var(--gray-700);
        margin-bottom: 14px;
        font-weight: $normal;
    }
    .number {
        padding: 7px 14px;
        border-radius: $radius-pill;
        background-color: var(--light);
        width: max-content;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 2px;
        .no {
            font-size: $size-16;
            font-weight: $medium;
            color: var(--dark);
            line-height: 20px;
        }
        .text {
            font-size: $size-11;
            color: var(--gray-600);
        }
    }
    &.border-l {
        &::before {
            content: "";
            width: 1px;
            height: 70%;
            background-color: var(--gray-200);
            position: absolute;
            left: 0;
        }
    }
    &.border-t {
        &::before {
            content: "";
            width: 80%;
            height: 1px;
            background-color: var(--gray-200);
            position: absolute;
            top: 0;
            right: 0;
        }
    }
    &.border-r {
        &::before {
            content: "";
            width: 1px;
            height: 80%;
            background-color: var(--gray-200);
            position: absolute;
            right: 0;
        }
    }
    &.border-b {
        &::before {
            content: "";
            width: 80%;
            height: 1px;
            background-color: var(--gray-200);
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
}