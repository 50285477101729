@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.library-section {
    margin-top: 30px;
    .item-file-lib {
        margin-bottom: 24px;
    }
}
.item-file-lib {
    position: relative;
    overflow: hidden;
    border-radius: $radius-10;
    padding: 20px;
    border: 1px solid var(--gray-200);
    display: flex;
    gap: 16px;
    text-decoration: none;
    @include shadow-default;
    .file-head {
        .icon-file {
            img {
                width: 34px;
                height: 38px;
                object-fit: contain;
            }
        }
    }
    .file-body {
        //-width: calc(100% - 56px);
        .category {
            padding: 6px 10px;
            font-size: 10px;
            font-weight: $medium;
            color: var(--gray-700);
            background-color: rgba($gray-200, 0.7);
            margin-bottom: 6px;
            display: inline-block;
            border-radius: 8px;
        }
        .title {
            font-size: $size-14;
            font-weight: $medium;
            color: var(--dark);
            margin-bottom: 3px;
            display: flex;
            .name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                @include media-breakpoint-up(xxl) {
                    max-width: calc(100vw - 86vw);
                }
            }
        }
        .file-info {
            display: flex;
            align-items: center;
            span {
                &:last-child {
                    &::before {
                        content: "";
                        width: 3px;
                        height: 3px;
                        background-color: var(--gray-600);
                        margin: 0 6px;
                        display: inline-block;
                        border-radius: 10px;
                    }
                }
            }
            .size {
                @include font-en;
                direction: ltr;
                font-size: $size-12;
                color: var(--gray-700);
            }
            .date {
                font-size: $size-12;
                color: var(--gray-700);
            }
        }
    }
    .btn_download {
        position: absolute;
        left: 20px;
        top: 20px;
        background-color: var(--light);
        border: 0;
        opacity: 1;
        pointer-events: all;
        @include transition-all;
        i {
            font-size: 16px;
            color: var(--gray-600);
            @include transition-all;
        }
    }
    .progress {
        position: absolute;
        height: 2px;
        border-radius: 0;
        background-color: var(--gray-300);
        left: 0;
        right: 0;
        bottom: 0;
        .progress-bar {
            border-radius: $radius-10;
            background-color: $green;
        }
    }
    .btn_cancel {
        position: absolute;
        padding: 4px;
        border: 0 !important;
        color: var(--gray-700);
        font-size: $size-12;
        font-weight: $medium;
        left: 20px;
        bottom: 12px;
        @include transition-all;
        &:hover {
            color: $red;
        }
    }
    &.downloadNow {
        .file-head,
        .file-body {
            opacity: 0.5;
        }
        .btn_download {
            opacity: 0;
            pointer-events: none;
        }
    }
    &:hover {
        .btn_download {
            background-color: var(--primary);
            i {
                color: $white;
            }
        }
    }
    &.new-update {
        &::before {
            content: "";
            width: 6px;
            height: 6px;
            background-color: var(--primary);
            border-radius: $radius-circle;
            position: absolute;
            top: 10px;
            right: 10px;
            animation: fade-in 1.5s ease-in-out infinite both;
        }
    }
}

