@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

header {
    grid-area: header;
    background-color: var(--light);
    border-bottom: 1px solid var(--gray-200);
    .navbar {
        padding: 0;
        flex-wrap: wrap;
        .nav-top-main {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
            padding: 24px 0;
            position: relative;
            @include media-breakpoint-down(lg) {
                padding: 18px 0;
            }
            .nav-welcome-right {
                @include media-breakpoint-up(lg) {
                    display: flex;
                }
                @include media-breakpoint-down(lg) {
                    width: 100%;
                }
                align-items: center;
                gap: 40px;
                .sms-welcome {
                    span {
                        font-size: $size-12;
                        color: var(--gray-700);
                        display: block;
                        margin-bottom: 2px;
                    }
                    h1 {
                        font-size: $size-22;
                        font-weight: $semiBold;
                        color: var(--dark);
                        margin: 0;
                    }
                }
                .form-search-advanced-all {
                    @include media-breakpoint-down(lg) {
                        margin-top: 20px;
                    }
                    .form-control {
                        height: 40px;
                        width: 250px;
                        @include media-breakpoint-down(lg) {
                            width: 100%;
                            height: 48px;
                        }
                    }
                }
            }
            .nav-left {
                display: flex;
                align-items: center;
                gap: 24px;
                .brand-logo {
                    @include media-breakpoint-down(lg) {
                        display: none;
                    }
                }
                .navbar-toggler {
                    width: 40px;
                    height: 40px;
                    border-radius: $radius-circle;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid var(--gray-200);
                    padding: 0;
                    @include media-breakpoint-down(lg) {
                        display: inline-flex;
                        position: absolute;
                        top: 18px;
                        left: 0;
                    }
                    // i {
                    //     font-size: 19px;
                    //     color: var(--gray-700);
                    // }
                    .icon-menu {
                        display: flex;
                        justify-content: center;
                        span {
                            height: 2px;
                            width: 16px;
                            background-color: var(--gray-700);
                            display: block;
                            position: absolute;
                            border-radius: $radius-10;
                            transition: 0.4s cubic-bezier(0.77, 0, 0.18, 1);
                            &:first-child {
                                top: 14px;
                            }
                            &:last-child {
                                bottom: 15px;
                            }
                        }
                    }
                    &[aria-expanded="true"] {
                        .icon-menu {
                            span {
                                &:first-child {
                                    transform: translateY(4px) rotate(45deg);
                                }
                                &:last-child {
                                    transform: translateY(-3px) rotate(-45deg);
                                }
                            }
                        }
                    }
                }
                .theme-mode {
                    .label-check {
                        width: 40px;
                        height: 40px;
                        background-color: var(--gray-200);
                        border-radius: $radius-circle;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        border: 0;
                        padding: 0;
                        transition: 0.4s all;
                        .icon {
                            width: 30px;
                            height: 30px;
                            border-radius: $radius-circle;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: var(--white);
                            border: 1px solid var(--gray-200);
                            transition: 0.4s all;
                        }
                        .iconsax {
                            position: absolute;
                            font-size: 16px;
                            color: var(--dark);
                            transition: color, 0.4s all;
                            &.iconsax-moon {
                                opacity: 0;
                                pointer-events: none;
                                transform: translateY(-16px);
                            }
                        }
                    }
                    @include media-breakpoint-down(lg) {
                        position: absolute;
                        top: 18px;
                        left: 55px;
                    }
                    .theme-switch {
                        &:checked {
                            + {
                                .lightMode {
                                    .iconsax-moon {
                                        transform: translateY(0);
                                        opacity: 1;
                                        pointer-events: auto;
                                    }
                                    .iconsax-sun-1 {
                                        opacity: 0;
                                        transform: translateY(16px);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .nav-menu-main {
            @include media-breakpoint-up(lg) {
                padding-top: 8px;
            }
            .navbar-nav {
                padding: 0;
                gap: 18px;
                @include media-breakpoint-down(lg) {
                    gap: 10px;
                    padding-bottom: 24px;
                }
                .nav-item {
                    padding: 0;
                    .nav-link {
                        display: inline-flex;
                        align-items: center;
                        padding: 20px 10px;
                        position: relative;
                        justify-content: center;
                        height: 60px;
                        @include media-breakpoint-down(lg) {
                            width: 100%;
                            justify-content: flex-start;
                            padding: 14px 16px;
                            height: inherit;
                            border-radius: $radius-10;
                        }
                        .iconsax {
                            font-size: 18px;
                            color: var(--gray-700);
                            margin-left: 8px;
                            transform: scale(-1, 1);
                        }
                        .name {
                            font-weight: $medium;
                            font-size: $size-13;
                            display: inline-block;
                            color: var(--dark);
                        }
                        .badge {
                            margin-right: 6px;
                            @include media-breakpoint-down(lg) {
                                position: absolute;
                                left: 0;
                            }
                        }
                        &::before {
                            content: "";
                            height: 2px;
                            background-color: var(--primary);
                            width: 100%;
                            position: absolute;
                            bottom: 0;
                            transition: 0.4s all;
                            opacity: 0;
                            @include media-breakpoint-down(lg) {
                                display: none;
                            }
                        }
                        &.active {
                            .iconsax {
                                color: var(--dark);
                            }
                            &::before {
                                height: 2px;
                                opacity: 1;
                            }
                            @include media-breakpoint-down(lg) {
                                background-color: var(--gray-200);
                            }
                        }
                    }
                }
            }
        }
        .profile-user-mobile {
            display: flex;
            width: 100%;
            align-items: center;
            gap: 16px;
            padding: 0;
            padding-top: 16px;
            outline: 0 !important;
            box-shadow: none !important;
            border: 0;
            @include media-breakpoint-up(xl) {
                display: none;
            }
            .body_img {
                position: relative;
                .brand-tts {
                    position: absolute;
                    bottom: -1px;
                    left: -4px;
                    img {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
            .user-img {
                width: 50px;
                height: 50px;
                border-radius: $radius-circle !important;
                object-fit: cover;
            }
            .display-info {
                font-family: "Mukta", sans-serif;
                text-align: right;
                h2 {
                    font-size: $size-16;
                    font-weight: $medium;
                    color: var(--dark);
                    margin-bottom: 4px;
                    line-height: 20px;
                }
                p {
                    font-size: $size-13;
                    color: var(--gray-700);
                    margin: 0;
                    line-height: 20px;
                }
            }
            &::after {
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.077 8.00613C12.2217 7.86139 12.2896 7.67717 12.2896 7.49375C12.2896 7.31033 12.2217 7.12611 12.077 6.98137C11.7962 6.70065 11.3329 6.70065 11.0522 6.98137L8.11054 9.92304C7.82982 10.2038 7.82982 10.6671 8.11054 10.9478L11.0522 13.8895C11.3329 14.1702 11.7962 14.1702 12.077 13.8895C12.3577 13.6087 12.3577 13.1454 12.077 12.8647L9.64767 10.4354L12.077 8.00613Z' fill='%23808A98' stroke='%23808A98' stroke-width='0.2'/%3E%3C/svg%3E%0A");
                background-size: 20px;
                height: 20px;
                width: 20px;
                left: 0;
                position: absolute;
            }
        }
    }
    &.main-header {
        padding: 0 50px;
        @include media-breakpoint-down(lg) {
            padding: 0 20px;
        }
    }
}

//Notification
.drop-notifications {
    @include media-breakpoint-down(lg) {
        position: absolute;
        top: 18px;
        left: 110px;
    }
    .badge-anime {
        position: absolute;
        width: 5px;
        height: 5px;
        background-color: $red;
        display: block;
        top: 7px;
        right: 7px;
        border-radius: $radius-circle;
        @include transition-all;
    }
    .btn {
        &::after {
            display: none;
        }
        width: 40px;
        height: 40px;
        background-color: var(--gray-200);
        border-radius: $radius-circle;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: 0.4s all;
        outline: 0 !important;
        border: 0 !important;
        padding: 0;
        .icon {
            width: 30px;
            height: 30px;
            border-radius: $radius-circle;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--white);
            border: 1px solid var(--gray-200);
            transition: 0.4s all;
            .iconsax {
                position: absolute;
                font-size: 16px;
                color: var(--dark);
                &::before {
                    @include transition-all;
                }
            }
        }
        &.show {
            .icon {
                i {
                    &::before {
                        content: "";
                        background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.9365 7.57852C14.7438 7.20201 14.3613 6.96801 13.9383 6.96801H10.2048V3.00051C10.2048 2.50326 9.90027 2.08251 9.42927 1.92951C8.95602 1.77576 8.46777 1.93701 8.17677 2.33751L3.15479 9.25252C2.90579 9.59677 2.87129 10.0445 3.06404 10.4225C3.25679 10.7983 3.63929 11.0323 4.06229 11.0323H7.79502V15.0005C7.79502 15.497 8.09952 15.917 8.57052 16.07C8.68902 16.109 8.80902 16.1278 8.92677 16.1278C9.27627 16.1278 9.60702 15.9628 9.82452 15.6628L14.8458 8.74777C15.0948 8.40352 15.1293 7.95577 14.9365 7.57852Z' fill='%23162842'/%3E%3C/svg%3E%0A");
                        background-size: 16px;
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }
    .dropdown-menu {
        background-color: var(--white);
        padding: 24px 12px !important;
        border-radius: 10px;
        width: 300px;
        border: 1px solid var(--light);
        display: block;
        opacity: 0;
        pointer-events: none;
        @include shadow-lg;
        transition: .3s cubic-bezier(0.65,0.05,0.36,1);
        transform: translateY(16px);
        left: 0%;
        @include media-breakpoint-down(sm) {
            left: -70px;
        }
        .content-menu-notifications {
            max-height: calc(100vh - 55vh);
            overflow: auto;
            padding-left: 16px;
        }
        &.show {
            opacity: 1;
            pointer-events: auto;
            transform: translateY(6px);
        }
    }
    .item-notification-inside {
        position: relative;
        text-align: right;
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 10px 12px;
        border-radius: 10px;
        background-color: var(--white);
        cursor: pointer;
        @include transition-all;
        &:hover {
            background-color: var(--light);
        }
        .icon-noti {
            width: 34px;
            height: 35px;
            border-radius: $radius-circle;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            &.bg-purple {
                background-color: rgba($purple, 0.1);
                svg {
                    width: 18px;
                    height: 18px;
                    path {
                        fill: $purple;
                    }
                }
            }
            &.bg-primary {
                background-color: rgba($primary, 0.1) !important;
                svg {
                    width: 18px;
                    height: 18px;
                    path {
                        fill: $primary;
                    }
                }
            }
            &.bg-orange {
                background-color: rgba($orange, 0.1) !important;
                svg {
                    width: 18px;
                    height: 18px;
                    path {
                        fill: $orange;
                    }
                }
            }
            &.bg-green {
                background-color: rgba($green, 0.1) !important;
                svg {
                    width: 18px;
                    height: 18px;
                    path {
                        fill: $green;
                    }
                }
            }
            &.bg-blue {
                background-color: rgba($blue, 0.1) !important;
                svg {
                    width: 18px;
                    height: 18px;
                    path {
                        fill: $blue;
                    }
                }
            }
        }
        .display-text {
            .title {
                font-size: $size-12;
                color: var(--dark);
                font-weight: $medium;
                margin-bottom: 3px;
            }
            .part-2 {
                display: flex;
                align-items: center;
                gap: 6px;
            }
            .date,
            .name {
                font-size: $size-11;
                color: var(--gray-600);
            }
            .name {
                display: flex;
                align-items: center;
                gap: 6px;
                &::before {
                    content: "";
                    width: 3px;
                    height: 3px;
                    border-radius: $radius-circle;
                    display: flex;
                    background-color: var(--gray-400);
                }
            }
        }
        .badge {
            background-color: $red;
            width: 5px;
            height: 5px;
            position: absolute;
            top: 6px;
            right: 5px;
            display: flex;
            padding: 0;
        }
    }
}
