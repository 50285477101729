@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.tts-coplete-profile {
    .tts-head-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 32px 0;
        h1 {
            font-size: $size-22;
            font-weight: $semiBold;
            margin-bottom: 4px;
            color: var(--dark);
        }
        p {
            margin: 0;
            font-size: $size-13;
            color: var(--gray-700);
        }
        .validate-alert {
            font-size: $size-13;
            color: $orange;
            margin: 0;
        }
    }
    .form-step {
        display: none;
        &.hidden {
            display: none;
        }
        &.show {
            display: block;
        }
    }
    .form-step-active {
        display: block;
    }
    .show {
        opacity: 1;
        pointer-events: all;
    }
    .hidden {
        opacity: 0;
        pointer-events: none
    }
    .theme-mode {
        display: none;
    }
}

.wrapper-progress-step {
    background-color: var(--light);
    border-top: 1px solid var(--gray-200);
    border-bottom: 1px solid var(--gray-200);
    padding: 34px 0;
    margin-bottom: 20px;
    @include media-breakpoint-down(sm) {
        padding: 18px 0;
    }
    .progress-text {
        display: flex;
        align-items: center;
        @include media-breakpoint-down(sm) {
            justify-content: space-between;
        }
        .progress-step {
            display: flex;
            align-items: center;
            gap: 10px;
            flex-wrap: wrap;
            .number {
                font-family: "Mukta", sans-serif;
                font-size: $size-14;
                font-weight: $semiBold;
                color: var(--gray-700);
                width: 32px;
                height: 32px;
                border-radius: $radius-circle;
                background-color: var(--gray-200);
                display: inline-flex;
                align-items: center;
                justify-content: center;
                @include transition-all;
                &::before {
                    content: "";
                    background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.5775 8.1675C4.3775 8.1675 4.1875 8.0875 4.0475 7.9475L1.2175 5.1175C0.9275 4.8275 0.9275 4.3475 1.2175 4.0575C1.5075 3.7675 1.9875 3.7675 2.2775 4.0575L4.5775 6.3575L9.7175 1.2175C10.0075 0.9275 10.4875 0.9275 10.7775 1.2175C11.0675 1.5075 11.0675 1.9875 10.7775 2.2775L5.1075 7.9475C4.9675 8.0875 4.7775 8.1675 4.5775 8.1675Z' fill='white' stroke='white' stroke-width='0.2'/%3E%3C/svg%3E%0A");
                    background-size: 12px;
                    width: 12px;
                    height: 8px;
                    background-repeat: no-repeat;
                    position: absolute;
                    transform: scale(0.75);
                    opacity: 0;
                    @include transition-all;
                }
            }
            .name {
                font-size: $size-13;
                font-weight: $medium;
                color: var(--gray-700);
                @include transition-all;
            }
            &::after {
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.59993 6.07061C9.71967 5.95086 9.77589 5.79832 9.77589 5.64656C9.77589 5.4948 9.71967 5.34226 9.59993 5.22252C9.36754 4.99013 8.98423 4.99013 8.75184 5.22252L6.39851 7.57585C6.16612 7.80824 6.16612 8.19155 6.39851 8.42394L8.75184 10.7773C8.98423 11.0097 9.36754 11.0097 9.59993 10.7773C9.83232 10.5449 9.83232 10.1616 9.59993 9.92919L7.67064 7.9999L9.59993 6.07061Z' fill='%23808A98' stroke='%23808A98' stroke-width='0.2'/%3E%3C/svg%3E%0A");
                background-size: 16px;
                width: 16px;
                height: 16px;
                background-repeat: no-repeat;
                margin: 0 24px;
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
            &:last-child {
                &::after {
                    display: none;
                }
            }
            &.progress-step-finish {
                .number {
                    background-color: var(--green);
                    &::before {
                        transform: scale(1);
                        opacity: 1;
                    }
                    span {
                        opacity: 0;
                    }
                }
                .name {
                    color: var(--dark);
                }
            }
            &.progress-step-active {
                .number {
                    background-color: var(--dark);
                    span {
                        color: var(--white);
                    }
                }
                .name {
                    color: var(--dark);
                }
            }
        }
    }
}

.buttons-steps {
    padding: 40px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.wrapper-settings-main {
    margin: 50px 0;
}