@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.badge {
    padding: 3px 8px;
    background-color: var(--gray-200);
    border-radius: $radius-pill;
    font-family: "Mukta", sans-serif;
    font-size: $size-12;
    font-weight: $semiBold;
    line-height: 16px;
    color: var(--gray-700) !important;
}
