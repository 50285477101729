@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.form-group {
    .form-control {
        padding: 12px 18px;
        @include shadow-default;
        border-radius: $radius-10;
        border: 1px solid var(--gray-200);
        font-size: $size-14;
        font-weight: $medium;
        color: var(--dark);
        background-color: var(--white);
        @include transition-all;
        height: 45px;
        &.sm {
            padding: 10px 16px;
        }
        &:focus {
            border-color: var(--gray-600);
        }
        &::placeholder {
            font-size: $size-12;
            font-weight: $normal;
            color: var(--gray-700);
        }
        &.en {
            font-family: "Mukta", sans-serif;
        }
        &.invalid {
            border: 1px solid $red;
            animation: shaking 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
            transform: translate3d(0, 0, 0);
            backface-visibility: hidden;
            perspective: 1000px;
            transition: all 0.4s ease-in-out;
        }
    }
    .input_group {
        position: relative;
        display: flex;
        align-items: center;
        .display-text-email {
            position: absolute;
            font-size: $size-14;
            font-weight: $medium;
            color: var(--gray-700);
            right: 18px;
            direction: ltr;
            @include font-en;
        }
        .icon-success {
            background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.5775 8.1675C4.3775 8.1675 4.1875 8.0875 4.0475 7.9475L1.2175 5.1175C0.9275 4.8275 0.9275 4.3475 1.2175 4.0575C1.5075 3.7675 1.9875 3.7675 2.2775 4.0575L4.5775 6.3575L9.7175 1.2175C10.0075 0.9275 10.4875 0.9275 10.7775 1.2175C11.0675 1.5075 11.0675 1.9875 10.7775 2.2775L5.1075 7.9475C4.9675 8.0875 4.7775 8.1675 4.5775 8.1675Z' fill='%232DB666' stroke='%232DB666' stroke-width='0.2'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: 11px;
            width: 11px;
            height: 8px;
            position: absolute;
            right: 41%;
            transform: scale(0.75);
            opacity: 0;
            @include transition-all;
        }
        .ltr {
            text-align: left;
            direction: ltr;
            text-transform: lowercase;
            &.is-valid-mail {
                ~ {
                    .icon-success {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            }
        }
    }
    .feedback {
        font-size: $size-11;
        color: var(--gray-700);
        margin-top: 4px;
        &.text-red {
            color: $red;
        }
    }
}

.form-verification-code {
    .input_group {
        justify-content: center;
        gap: 12px;
        direction: ltr;
        .form-control {
            max-width: 39px;
            border-radius: 8px;
            padding: 5px;
            font-weight: $semiBold;
            font-size: $size-20;
            text-align: center;
            font-family: "Mukta", sans-serif;
            &::placeholder {
                font-size: $size-20;
                font-weight: $medium;
            }
            &:focus {
                &::placeholder {
                    opacity: 0;
                }
            }
        }
    }
}

.tts-item-form {
    border-bottom: 1px solid var(--gray-200);
    padding: 32px 0;
    .tts-title-form {
        @include media-breakpoint-down(md) {
            margin-bottom: 18px;
        }
        h3 {
            font-size: $size-14;
            font-weight: $medium;
            margin: 0;
            color: var(--dark);
        }
        p {
            margin-top: 6px;
            color: var(--gray-700);
            font-size: $size-12;
            margin-bottom: 0;
        }
    }
    &.email {
        .form-group {
            .input-group {
                position: relative;
                flex-direction: row-reverse;
                .form-control {
                    direction: ltr;
                }
                .input-group-text {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: row-reverse;
                    padding: 0 18px;
                    background-color: var(--light);
                    border-left: 1px solid var(--gray-200);
                    width: 60%;
                    border-color: var(--gray-200);
                    border-radius: 10px;
                    span {
                        font-weight: $medium;
                        font-family: "Mukta", sans-serif;
                        color: var(--gray-700);
                        display: inline-flex;
                        font-size: $size-14;
                        direction: ltr;
                    }
                    .iconsax {
                        font-size: 18px;
                    }
                }
            }
        }
    }
    &.social-networks {
        .form-group {
            margin-bottom: 8px;
            .input-group {
                .form-control {
                    &::placeholder {
                        font-family: "IBM Plex Sans Arabic", sans-serif;
                    }
                }
                .input-group-text {
                    width: 120px !important;
                }
                .icon-app {
                    width: 20px;
                    object-fit: contain;
                    position: absolute;
                    right: 16px;
                    height: 100%;
                    z-index: 4;
                }
            }
            &.twitter,
            &.linkedin {
                .icon-app {
                    width: 17px;
                }
            }
        }
    }
    &.asana {
        .form-group {
            .input-group {
                display: flex;
                flex-direction: row;
                .form-control {
                    font-family: "Mukta", sans-serif;
                    border-radius: 0;
                    border-top-right-radius: 10px !important;
                    border-bottom-right-radius: 10px !important;
                }
            }
            .input-group-text {
                width: auto !important;
                border-radius: 0 !important;
                border-top-left-radius: 10px !important;
                border-bottom-left-radius: 10px !important;
            }
            .feedback {
                font-family: "Mukta", sans-serif;
                font-size: $size-12;
                color: var(--gray-700);
                direction: ltr;
                text-align: left;
                margin-top: 4px;
                span {
                    color: var(--gray-800);
                    text-decoration: underline;
                    font-weight: $medium;
                }
            }
        }
    }
}

.form-group {
    .nice-select {
        width: 100%;
        padding: 12px 18px;
        @include shadow-default;
        border-radius: $radius-10;
        border: 1px solid var(--gray-200) !important;
        font-size: $size-14;
        font-weight: $medium;
        color: var(--dark);
        @include transition-all;
        height: 45px;
        display: flex;
        align-items: center;
        background-color: var(--white);
        &::after {
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.07073 6.40007C5.95099 6.28033 5.79844 6.22411 5.64668 6.22411C5.49493 6.22411 5.34238 6.28033 5.22264 6.40007C4.99025 6.63246 4.99025 7.01577 5.22264 7.24816L7.57597 9.60149C7.80836 9.83388 8.19168 9.83388 8.42406 9.60149L10.7774 7.24816C11.0098 7.01577 11.0098 6.63246 10.7774 6.40007C10.545 6.16768 10.1617 6.16768 9.92931 6.40007L8.00002 8.32936L6.07073 6.40007Z' fill='%23808A98' stroke='%23808A98' stroke-width='0.2'/%3E%3C/svg%3E%0A");
            border: 0;
            background-size: 16px;
            width: 16px;
            height: 16px;
            transform: rotate(0);
            transform-origin: inherit !important;
            left: 10px;
            right: inherit;
            padding: 0;
            top: 14px;
            margin: 0;
        }
        .current {
            font-size: $size-13;
            font-weight: $medium;
        }
        &.open {
            &::after {
                transform: rotate(-180deg);
            }
        }
        .nice-select-dropdown {
            width: 100%;
            @include shadow-md;
            border-radius: $radius-10;
            border: 1px solid var(--gray-200);
            background-color: var(--white);
            ul {
                padding: 10px 0;
                margin: 0;
            }
            .option {
                position: relative;
                line-height: normal;
                padding: 10px 34px 10px 18px;
                font-size: $size-13;
                font-weight: $medium;
                color: var(--gray-700);
                text-align: right;
                display: flex;
                border-radius: 0;
                align-items: center;
                border-bottom: 1px solid var(--light);
                &:last-child {
                    border-bottom: 0;
                }
                &::after {
                    content: "";
                    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.58238 7.58376C4.38238 7.58376 4.19238 7.50376 4.05238 7.36376L1.22238 4.53376C0.932383 4.24376 0.932383 3.76376 1.22238 3.47376C1.51238 3.18376 1.99238 3.18376 2.28238 3.47376L4.58238 5.77376L9.72238 0.63376C10.0124 0.34376 10.4924 0.34376 10.7824 0.63376C11.0724 0.92376 11.0724 1.40376 10.7824 1.69376L5.11238 7.36376C4.97238 7.50376 4.78238 7.58376 4.58238 7.58376Z' fill='%23162842' stroke='%235D6879' stroke-width='0.2'/%3E%3C/svg%3E%0A");
                    background-size: 10px;
                    width: 10px;
                    height: 8px;
                    position: absolute;
                    background-repeat: no-repeat;
                    right: 18px;
                    transform: scale(0.8);
                    opacity: 0;
                    @include transition-all;
                }
                &.selected {
                    color: var(--dark);
                    background-color: transparent;
                    &::after {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
                &:hover {
                    background-color: var(--light);
                }
            }
        }
    }
}

.select-team-form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    .form-group {
        .btn-check {
            &:checked {
                ~ {
                    .btn {
                        background-color: var(--gray-200) !important;
                        color: var(--dark) !important;
                    }
                }
            }
        }
    }
}

// FILEPOND
.filepond--root {
    .filepond--credits {
        display: none;
    }
    .filepond--drop-label {
        color: var(--gray-700);
        .filepond--label-action {
            color: var(--dark);
            text-decoration: none;
        }
        &.filepond--drop-label {
            label {
                font-weight: $normal;
                color: var(--gray-700);
                font-size: $size-14;
            }
        }
    }
    .filepond--panel-root {
        border-radius: $radius-10;
        background-color: var(--gray-200);
    }
    .filepond--panel-top {
        &::after {
            opacity: 0;
        }
    }
    .filepond--panel-bottom {
        &::before {
            opacity: 0;
        }
    }
    .filepond--item-panel {
        background-color: var(--gray-600);
        border-radius: $radius-10;
    }
    .filepond--drip {
        border-radius: $radius-10;
    }
    [data-filepond-item-state*="error"],
    [data-filepond-item-state*="invalid"] {
        .filepond--item-panel {
            background-color: $red;
        }
    }
    [data-filepond-item-state="processing-complete"] {
        .filepond--item-panel {
            background-color: $green;
        }
    }
    .filepond--file-action-button {
        &:hover,
        &:focus {
            box-shadow: 0 0 0 0.125em $white !important;
        }
    }
}

.select-hr-works {
    .form-group {
        margin-bottom: 8px;
        .input-group {
            display: flex;
            flex-wrap: nowrap;
            .input-group-text {
                padding: 0 18px;
                background-color: var(--light);
                border-left: 1px solid var(--gray-200);
                width: 56%;
                border-color: var(--gray-200);
                border-radius: 10px;
                border-radius: 0;
                border-top-right-radius: $radius-10 !important;
                border-bottom-right-radius: $radius-10 !important;
                span {
                    font-size: $size-13;
                    font-weight: $medium;
                    color: var(--gray-700);
                }
            }
            .nice-select {
                &:hover {
                    color: var(--primary);
                }
                &.toend {
                    border-radius: 0;
                    border-top-left-radius: $radius-10 !important;
                    border-bottom-left-radius: $radius-10 !important;
                    border-right: 0 !important;
                    padding-right: 22px;
                    &::before {
                        content: "";
                        background-image: url("data:image/svg+xml,%3Csvg width='19' height='44' viewBox='0 0 19 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 0.999999L2.23128 19.3968C0.947319 20.8948 0.947319 23.1052 2.23128 24.6032L18 43' stroke='%23ECEEF0'/%3E%3C/svg%3E%0A");
                        background-size: 20px;
                        width: 20px;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: -10px;
                        background-repeat: no-repeat;
                    }
                }
            }
        }
    }
}

.simple-saech {
    .input_group {
        position: relative;
        .form-control {
            padding-right: 38px;
        }
        i {
            position: absolute;
            right: 16px;
            font-size: 14px;
        }
    }
}

input {
    &[type="search"] {
        &::-webkit-search-cancel-button {
            position: absolute;
            -webkit-appearance: none;
            left: 14px;
            background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='9' cy='9' r='9' fill='%23DEE1E5'/%3E%3Cpath d='M6.90123 11.7719C6.75686 11.7719 6.61249 11.7187 6.49852 11.6047C6.27816 11.3844 6.27816 11.0197 6.49852 10.7993L10.7992 6.49861C11.0196 6.27826 11.3843 6.27826 11.6047 6.49861C11.825 6.71896 11.825 7.08369 11.6047 7.30404L7.30395 11.6047C7.19757 11.7187 7.0456 11.7719 6.90123 11.7719Z' fill='%235D6879'/%3E%3Cpath d='M11.2019 11.7719C11.0576 11.7719 10.9132 11.7187 10.7992 11.6047L6.49852 7.30404C6.27816 7.08369 6.27816 6.71896 6.49852 6.49861C6.71887 6.27826 7.0836 6.27826 7.30395 6.49861L11.6047 10.7993C11.825 11.0197 11.825 11.3844 11.6047 11.6047C11.4907 11.7187 11.3463 11.7719 11.2019 11.7719Z' fill='%235D6879'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 18px;
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            @include transition-all;
        }
        &::-ms-clear {
            position: absolute;
            -webkit-appearance: none;
            left: 14px;
            background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='9' cy='9' r='9' fill='%23DEE1E5'/%3E%3Cpath d='M6.90123 11.7719C6.75686 11.7719 6.61249 11.7187 6.49852 11.6047C6.27816 11.3844 6.27816 11.0197 6.49852 10.7993L10.7992 6.49861C11.0196 6.27826 11.3843 6.27826 11.6047 6.49861C11.825 6.71896 11.825 7.08369 11.6047 7.30404L7.30395 11.6047C7.19757 11.7187 7.0456 11.7719 6.90123 11.7719Z' fill='%235D6879'/%3E%3Cpath d='M11.2019 11.7719C11.0576 11.7719 10.9132 11.7187 10.7992 11.6047L6.49852 7.30404C6.27816 7.08369 6.27816 6.71896 6.49852 6.49861C6.71887 6.27826 7.0836 6.27826 7.30395 6.49861L11.6047 10.7993C11.825 11.0197 11.825 11.3844 11.6047 11.6047C11.4907 11.7187 11.3463 11.7719 11.2019 11.7719Z' fill='%235D6879'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 18px;
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            @include transition-all;
        }
    }
}

.filter-head {
    .form-group {
        @include media-breakpoint-down(md) {
            margin-bottom: 10px;
            display: grid;
        }
    }
    .form-control,
    .nice-select {
        height: 42px;
    }
    .nice-select {
        background-color: var(--light);
        box-shadow: none !important;
        &::after {
            top: 12px;
        }
    }
}

.form-search-advanced-all {
    position: relative;
    .form-control {
        &:focus {
            @include media-breakpoint-up(lg) {
                width: 320px !important;
            }
        }
    }
    #autoComplete_list_1 {
        position: absolute;
        width: 100%;
        top: 46px;
        @include media-breakpoint-down(lg) {
            top: 54px;
        }
        background-color: var(--white);
        border-radius: $radius-10;
        padding: 14px 0;
        list-style: none;
        text-align: right;
        border: 1px solid var(--gray-200);
        @include shadow-lg;
        // opacity: 0;
        // pointer-events: none;
        overflow-x: hidden;
        max-height: calc(100vh / 3);
        //display: flex;
        z-index: 10;
        li {
            display: none;
            padding: 0;
            margin: 0 10px;
            a {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-decoration: none;
                cursor: pointer;
                padding: 10px 18px;
                background-color: var(--white);
                border-radius: $radius-10;
                @include transition-all;
                &:hover {
                    background-color: rgba($primary, 0.1);
                    .issue_name {
                        color: var(--primary);
                    }
                }
                span {
                    display: inline-block;
                }
            }
            .issue_name {
                color: var(--gray-800);
                font-size: $size-13;
                font-weight: $medium;
                @include transition-all;
                mark {
                    background-color: transparent;
                    padding: 0;
                    font-weight: $semiBold;
                    color: var(--primary);
                }
            }
            .type_name {
                color: var(--gray-600);
                font-size: 11px;
                font-weight: $medium;
            }
        }
        .status_result {
            font-size: $size-13;
            color: var(--gray-600);
            padding: 4px 18px 10px 18px;
            margin: 0;
        }
        &::-webkit-scrollbar {
            width: 16px !important;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
            margin-top: 8px;
            margin-bottom: 8px;
        }
        &::-webkit-scrollbar-thumb {
            background: var(--gray-400) !important;
            border-radius: $radius-10;
            border: 6px solid var(--white);
        }
    }
    .input_group {
        position: relative;
        .press-key {
            display: flex;
            gap: 2px;
            position: absolute;
            left: 10px;
            direction: ltr;
            @include transition-all;
            span {
                @include font-en;
                @include shadow-default;
                line-height: 12px;
                font-size: 10px;
                font-weight: $semiBold;
                color: var(--gray-600);
                display: flex;
                padding: 3px 5px;
                border-radius: 5px;
                border: 1px solid var(--gray-200);
                text-transform: uppercase;
            }
        }
    }
    .autoComplete_wrapper {
        width: 100%;
        &[aria-expanded="true"] {
            ~ {
                .press-key {
                    opacity: 0;
                }
            }
        }
    }
}
