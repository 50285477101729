@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.pagination {
    padding: 20px 0 !important;
    &.default {
        display: flex;
        @include list-unstyled();
        flex-wrap: wrap;
        padding: 0;
        gap: 0 8px;
        .page-item {
            padding: 0;
            .page-link {
                font-weight: $medium;
                border-radius: 8px !important;
                height: 34px;
                min-width: 34px;
                font-size: $size-14;
                font-weight: $medium;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border: 1px solid transparent;
                @include font-en;
                @include transition-all;
                color: var(--dark);
                background-color: transparent !important;
                padding: 0 4px;
                &:hover {
                    border-color: var(--gray-200);
                }
            }
            &.disabled {
                margin: 0 -8px;
                background-color: transparent;
                pointer-events: none;
                .page-link {
                    border: 0;
                    background-color: transparent;
                    color: var(--gray-600);
                }
            }
            &.active {
                .page-link {
                    border: 0;
                    background-color: var(--dark) !important;
                    color: var(--white);
                }
            }
        }
    }
}

.pagination {
    &.steps {
        justify-content: center;
        &:not(.disabled) {
            gap: 16px;
        }
        .page-item {
            .page-link {
                padding: 7px 18px;
                border-radius: $radius-10;
                position: relative;
                height: inherit;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                height: 36px;
                span {
                    font-size: $size-14;
                    color: var(--gray-700);
                    font-weight: $medium;
                    display: inline-block;
                }
                &[aria-label="Previous"] {
                    border: 0;
                    background-color: transparent;
                    &::before {
                        content: "";
                        background-image: url("data:image/svg+xml,%3Csvg width='5' height='7' viewBox='0 0 5 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.575851 5.42939C0.45611 5.54914 0.399895 5.70168 0.399895 5.85344C0.399895 6.0052 0.45611 6.15774 0.575851 6.27748C0.808237 6.50987 1.19155 6.50987 1.42394 6.27748L3.77727 3.92415C4.00966 3.69176 4.00966 3.30845 3.77727 3.07606L1.42394 0.722727C1.19155 0.490341 0.808238 0.490341 0.575852 0.722727C0.343466 0.955112 0.343466 1.33843 0.575852 1.57081L2.50514 3.5001L0.575851 5.42939Z' fill='%235D6879' stroke='%235D6879' stroke-width='0.2'/%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat;
                        width: 5px;
                        height: 8px;
                        background-size: 5px;
                        @include transition-bg;
                    }
                    &:hover {
                        background-color: var(--light);
                    }
                }
                &[aria-label="next"] {
                    border: 1px solid var(--gray-200);
                    background-color: transparent !important;
                    span {
                        color: var(--primary);
                    }
                    &::after {
                        content: "";
                        background-image: url("data:image/svg+xml,%3Csvg width='5' height='7' viewBox='0 0 5 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.42415 5.42939C4.54389 5.54914 4.6001 5.70168 4.6001 5.85344C4.6001 6.0052 4.54389 6.15774 4.42415 6.27748C4.19176 6.50987 3.80845 6.50987 3.57606 6.27748L1.22273 3.92415C0.990341 3.69176 0.990341 3.30845 1.22273 3.07606L3.57606 0.722727C3.80845 0.490341 4.19176 0.490341 4.42415 0.722727C4.65653 0.955112 4.65653 1.33843 4.42415 1.57081L2.49486 3.5001L4.42415 5.42939Z' fill='%23EA494E' stroke='%23EA494E' stroke-width='0.2'/%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat;
                        width: 5px;
                        height: 8px;
                        background-size: 5px;
                    }
                }
            }
        }
    }
    .pagination {
    }
}
