@use "../abstracts/variables" as *;


@mixin transition-bg {
    transition: background .4s all;
}

@mixin transition-color {
    transition: color, .4s all;
}

@mixin transition-all {
    transition: .4s all;
}

@mixin shadow-lg {
    box-shadow: 0 36px 48px -22px rgba(#231E1F, .1) !important;
}

@mixin shadow-md {
    box-shadow: 0 32px 24px 8px rgba($dark, .05) !important;
}

@mixin shadow-default {
    box-shadow: 0 2px 2px 0 rgba($dark, .01) !important;
}
@mixin font-en {
    font-family: 'Mukta',sans-serif;
}
