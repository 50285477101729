@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.item-pkg-tool {
    border-radius: $radius-10;
    padding: 24px;
    display: block;
    text-decoration: none;
    position: relative;
    .logo-tool {
        width: 38px;
        height: 38px;
        border-radius: $radius-10;
        margin-bottom: 16px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: $white;
        img {
            width: 21px;
            object-fit: contain;
        }
    }
    h4 {
        @include font-en;
        font-size: $size-16;
        color: $white;
        font-weight: $semiBold;
        margin-bottom: 8px;
    }
    p {
        font-size: $size-14;
        color: $white;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        max-height: 43px;
    }
    .icon-go {
        border-radius: $radius-circle;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 1;
        top: 24px;
        left: 24px;
        background-color: rgba($white, .1);
        @include transition-all;
        i {
            background-image: url("data:image/svg+xml,%3Csvg width='7' height='7' viewBox='0 0 7 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.62084 0.733502C5.62084 1.13448 5.28832 1.46701 4.88733 1.46701L1.47409 1.46701L1.47409 4.88026C1.47409 5.28125 1.14157 5.61377 0.740586 5.61377C0.339603 5.61377 0.00708006 5.28125 0.00708005 4.88026L0.00707986 0.733502C0.00707985 0.332519 0.339603 -4.30607e-06 0.740586 -4.3236e-06L4.88733 -4.50486e-06C5.28832 -4.52238e-06 5.62084 0.332518 5.62084 0.733502Z' fill='white'/%3E%3Cpath d='M7.0001 6.26943C7.0001 6.45525 6.93164 6.64107 6.78493 6.78777C6.50131 7.07139 6.03187 7.07139 5.74825 6.78777L0.212717 1.25224C-0.0709059 0.968618 -0.0709059 0.499176 0.212717 0.215554C0.496339 -0.068069 0.965796 -0.068069 1.24942 0.215554L6.78493 5.75108C6.93164 5.88801 7.0001 6.08361 7.0001 6.26943Z' fill='white'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: 7px;
            width: 7px;
            height: 7px;
            @include transition-all;
        }
    }
    &:hover {
        .icon-go {
            background-color: $white;
            transform: translate(5px, 5px);
            i {
                background-image: url("data:image/svg+xml,%3Csvg width='7' height='7' viewBox='0 0 7 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.62084 0.733502C5.62084 1.13448 5.28832 1.46701 4.88733 1.46701L1.47409 1.46701L1.47409 4.88026C1.47409 5.28125 1.14157 5.61377 0.740586 5.61377C0.339603 5.61377 0.00708006 5.28125 0.00708005 4.88026L0.00707986 0.733502C0.00707985 0.332519 0.339603 -4.30607e-06 0.740586 -4.3236e-06L4.88733 -4.50486e-06C5.28832 -4.52238e-06 5.62084 0.332518 5.62084 0.733502Z' fill='%23162842'/%3E%3Cpath d='M7.0001 6.26943C7.0001 6.45525 6.93164 6.64107 6.78493 6.78777C6.50131 7.07139 6.03187 7.07139 5.74825 6.78777L0.212717 1.25224C-0.0709059 0.968618 -0.0709059 0.499176 0.212717 0.215554C0.496339 -0.068069 0.965796 -0.068069 1.24942 0.215554L6.78493 5.75108C6.93164 5.88801 7.0001 6.08361 7.0001 6.26943Z' fill='%23162842'/%3E%3C/svg%3E%0A");
            }
        }
    }
}


.tools-section {
    .item-pkg-tool {
        margin-bottom: 24px;
    }
}