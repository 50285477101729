@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

/*
    // IMPORT 'IBM Plex Sans Arabic' FROM GOOGLE FONTS
    
    REGULAR   :   400
    MEDIUM    :   500
    SEMIBOLD  :   600
    BOLD      :   700

    // THESE ARE THE NAMES IF YOU ARE USING A DESIGN FIILE SUCH AS FIGMA.
*/

@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@400;500;600&display=swap'); // ENGLISH font-family: 'Mukta', sans-serif;
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@400;500;600;700&display=swap");

p {
    font-size: $size-16;
    color: var(--gray-800);
}

figure {
    .blockquote {
        margin-bottom: 24px;
    }
}


.title_folder_article {
    display: flex;
    align-items: center;
    gap: 12px;
    .icon_folder {
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.2' d='M6.56016 7.3125H2.25V4.5C2.25 4.35082 2.30926 4.20774 2.41475 4.10225C2.52024 3.99676 2.66332 3.9375 2.8125 3.9375H6.56016C6.68178 3.938 6.80005 3.97742 6.89766 4.05L9 5.625L6.89766 7.2C6.80005 7.27258 6.68178 7.312 6.56016 7.3125Z' fill='%231D1D22'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.4375 5.625C8.4375 5.31434 8.68934 5.0625 9 5.0625H15.1875C15.4859 5.0625 15.772 5.18103 15.983 5.392C16.194 5.60298 16.3125 5.88913 16.3125 6.1875V14.0625C16.3125 14.3609 16.194 14.647 15.983 14.858C15.772 15.069 15.4859 15.1875 15.1875 15.1875H2.8125C2.51413 15.1875 2.22798 15.069 2.017 14.858C1.80603 14.647 1.6875 14.3609 1.6875 14.0625V7.3125C1.6875 7.00184 1.93934 6.75 2.25 6.75C2.56066 6.75 2.8125 7.00184 2.8125 7.3125V14.0625H15.1875V6.1875H9C8.68934 6.1875 8.4375 5.93566 8.4375 5.625Z' fill='%231D1D22'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.017 3.7045C2.22798 3.49353 2.51413 3.375 2.8125 3.375H6.56016L6.56247 3.375C6.80422 3.376 7.0393 3.45436 7.2333 3.59862L7.23492 3.59982L9.33726 5.17482C9.47904 5.28104 9.5625 5.44784 9.5625 5.625C9.5625 5.80216 9.47904 5.96896 9.33726 6.07518L7.23492 7.65018L7.2333 7.65138C7.0393 7.79564 6.80422 7.874 6.56247 7.875L6.56016 7.3125V7.875H2.25C1.93934 7.875 1.6875 7.62316 1.6875 7.3125V4.5C1.6875 4.20163 1.80603 3.91548 2.017 3.7045ZM6.55808 6.75C6.55943 6.74995 6.56074 6.74951 6.56185 6.74873C6.5619 6.74869 6.56196 6.74866 6.56201 6.74862L8.06183 5.625L6.56201 4.50138C6.56196 4.50134 6.5619 4.5013 6.56185 4.50127C6.56074 4.50049 6.55943 4.50005 6.55807 4.5L2.8125 4.5L2.8125 6.75H6.55808Z' fill='%231D1D22'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 20px;
        width: 20px;
        height: 20px;
    }
    h2 {
        font-size: $size-18;
        font-weight: $medium;
        color: var(--dark);
        margin: 0;
    }
}

.display-text-warning {
    h1,
    h2 {
        margin-bottom: 16px;
        font-weight: $semiBold;
        @include media-breakpoint-up(md) {
            line-height: 48px;
        }
    }
    p {
        margin-bottom: 32px;
        color: var(--gray-700);
    }
}


.display-title-main {
    h3 {
        font-size: $size-22;
        font-weight: $semiBold;
        color: var(--dark);
        margin-bottom: 6px;
    }
    p {
        font-size: $size-13;
        color: var(--gray-700);
    }
}