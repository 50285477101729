@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.spinner-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    .text {
        font-size: $size-13;
        font-weight: $medium;
        color: var(--dark);
    }
}
.spinner {
    width: 20px;
    height: 20px;
    position: relative;
    .double-bounce1,
    .double-bounce2 {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: var(--green);
        opacity: 0.6;
        position: absolute;
        top: 0;
        left: 0;

        -webkit-animation: sk-bounce 2s infinite ease-in-out;
        animation: sk-bounce 2s infinite ease-in-out;
    }

    .double-bounce2 {
        -webkit-animation-delay: -1s;
        animation-delay: -1s;
    }
    &.gray {
        .double-bounce1,
        .double-bounce2 {
            background-color: var(--gray-700);
        }
    }
}

@keyframes sk-bounce {
    0%,
    100% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
}

@keyframes shaking {
    10%,
    90% {
        transform: translate3d(-2px, 0, 0);
    }
    20%,
    80% {
        transform: translate3d(4px, 0, 0);
    }
    30%,
    50%,
    70% {
        transform: translate3d(-8px, 0, 0);
    }
    40%,
    60% {
        transform: translate3d(8px, 0, 0);
    }
}

@keyframes menu {
    0% {
        transform: scaleX(0) rotate(0);
    }
    100% {
        transform: scaleX(1) rotate(45deg);
    }
}

//  FADE OUT
@keyframes fadeOut {
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes fade-in {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}