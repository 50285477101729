@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

[data-theme="dark"] {
   --white: #121417;
   --dark: #{$light};
   --gray-300: #{$gray-800};
   --light: #16191d;
   --gray-200: #21262c;

   --gray-400: #{$gray-700};
   --gray-500: #{$gray-400};
   --gray-600: #{$gray-400};
   --gray-700: #{$gray-400};
   --gray-800: #{$gray-200};

   .form-group {
      .nice-select {
         .nice-select-dropdown {
            .option {
               &::after {
                  mix-blend-mode: luminosity;
                  filter: invert(1);
               }
            }
         }
      }
   }
   .box {
      &.gray {
         img {
            mix-blend-mode: luminosity;
            filter: invert(1);
         }
      }
   }
   .item-file-lib {
      .file-body {
         .category {
            background-color: rgb(27 30 33);
         }
      }
   }
   .select-hr-works {
      .form-group {
         .input-group {
            .nice-select {
               &.toend {
                  &::before {
                     mix-blend-mode: luminosity;
                     filter: invert(0.9);
                  }
               }
            }
         }
      }
   }
   .item-project {
      .tags {
         span {
            background-color: rgb(27 30 33);
         }
      }
   }
   .dividar-icon-team {
      .splide__arrows {
         .splide__arrow {
            &::before {
               mix-blend-mode: luminosity;
               filter: invert(0.9);
            }
         }
      }
   }
   .wrapper-list-calendar {
      .item__date-calendar {
         .item-day.weekend {
            .event-day {
               &::before {
                  mix-blend-mode: luminosity;
                  filter: invert(0.92);
               }
            }
         }
      }
   }
   .drop-notifications {
      .btn {
         &.show {
            .icon {
               i {
                  &::before {
                     background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.9365 7.57852C14.7438 7.20201 14.3613 6.96801 13.9383 6.96801H10.2048V3.00051C10.2048 2.50326 9.90027 2.08251 9.42927 1.92951C8.95602 1.77576 8.46777 1.93701 8.17677 2.33751L3.15479 9.25252C2.90579 9.59677 2.87129 10.0445 3.06404 10.4225C3.25679 10.7983 3.63929 11.0323 4.06229 11.0323H7.79502V15.0005C7.79502 15.497 8.09952 15.917 8.57052 16.07C8.68902 16.109 8.80902 16.1278 8.92677 16.1278C9.27627 16.1278 9.60702 15.9628 9.82452 15.6628L14.8458 8.74777C15.0948 8.40352 15.1293 7.95577 14.9365 7.57852Z' fill='white'/%3E%3C/svg%3E%0A");
                  }
               }
            }
         }
      }
   }
}
