@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;


.breadcrumb {
    .breadcrumb-item {
        font-size: $size-16;
        color: var(--gray-600);
        a {
            font-weight: $medium;
            color: var(--dark);
            text-decoration: none;
            @include transition-color;
            &:hover {
                color: var(--primary);
            }
        }
        &:not(&:first-child) {
            &::before {
                content: "\EA52";
                font-family: remixicon!important;
                float: right;
                padding: 0 8px;
                font-size: $size-20;
            }
        }
        
    }
}
