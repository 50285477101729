@font-face {
    font-family: "iconsax";
    src: url("../fonts/icons.ttf?e81b10f109cb62858db64ddad7f97a43") format("truetype"),
        url("../fonts/icons.woff?e81b10f109cb62858db64ddad7f97a43") format("woff"),
        url("../fonts/icons.woff2?e81b10f109cb62858db64ddad7f97a43") format("woff2"),
        url("../fonts/icons.eot?e81b10f109cb62858db64ddad7f97a43#iefix") format("embedded-opentype");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

i[class^="iconsax-"]:before,
i[class*=" iconsax-"]:before {
    font-family: iconsax !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.iconsax-24-support:before {
    content: "\f101";
}
.iconsax-3d-cube-scan:before {
    content: "\f102";
}
.iconsax-3d-rotate:before {
    content: "\f103";
}
.iconsax-3d-square:before {
    content: "\f104";
}
.iconsax-3dcube:before {
    content: "\f105";
}
.iconsax-3square:before {
    content: "\f106";
}
.iconsax-aave-aave:before {
    content: "\f107";
}
.iconsax-activity:before {
    content: "\f108";
}
.iconsax-add-circle:before {
    content: "\f109";
}
.iconsax-add-square:before {
    content: "\f10a";
}
.iconsax-add:before {
    content: "\f10b";
}
.iconsax-additem:before {
    content: "\f10c";
}
.iconsax-airdrop:before {
    content: "\f10d";
}
.iconsax-airplane-square:before {
    content: "\f10e";
}
.iconsax-airplane:before {
    content: "\f10f";
}
.iconsax-airpod:before {
    content: "\f110";
}
.iconsax-airpods:before {
    content: "\f111";
}
.iconsax-alarm:before {
    content: "\f112";
}
.iconsax-align-bottom:before {
    content: "\f113";
}
.iconsax-align-horizontally:before {
    content: "\f114";
}
.iconsax-align-left:before {
    content: "\f115";
}
.iconsax-align-right:before {
    content: "\f116";
}
.iconsax-align-vertically:before {
    content: "\f117";
}
.iconsax-android:before {
    content: "\f118";
}
.iconsax-ankr-ankr:before {
    content: "\f119";
}
.iconsax-apple:before {
    content: "\f11a";
}
.iconsax-aquarius:before {
    content: "\f11b";
}
.iconsax-archive-1:before {
    content: "\f11c";
}
.iconsax-archive-2:before {
    content: "\f11d";
}
.iconsax-archive-add:before {
    content: "\f11e";
}
.iconsax-archive-book:before {
    content: "\f11f";
}
.iconsax-archive-minus:before {
    content: "\f120";
}
.iconsax-archive-slash:before {
    content: "\f121";
}
.iconsax-archive-tick:before {
    content: "\f122";
}
.iconsax-archive:before {
    content: "\f123";
}
.iconsax-arrange-circle-2:before {
    content: "\f124";
}
.iconsax-arrange-circle:before {
    content: "\f125";
}
.iconsax-arrange-square-2:before {
    content: "\f126";
}
.iconsax-arrange-square:before {
    content: "\f127";
}
.iconsax-arrow-2:before {
    content: "\f128";
}
.iconsax-arrow-3:before {
    content: "\f129";
}
.iconsax-arrow-bottom:before {
    content: "\f12a";
}
.iconsax-arrow-circle-down:before {
    content: "\f12b";
}
.iconsax-arrow-circle-left:before {
    content: "\f12c";
}
.iconsax-arrow-circle-right:before {
    content: "\f12d";
}
.iconsax-arrow-circle-up:before {
    content: "\f12e";
}
.iconsax-arrow-down-1:before {
    content: "\f12f";
}
.iconsax-arrow-down-2:before {
    content: "\f130";
}
.iconsax-arrow-down:before {
    content: "\f131";
}
.iconsax-arrow-left-1:before {
    content: "\f132";
}
.iconsax-arrow-left-2:before {
    content: "\f133";
}
.iconsax-arrow-left-3:before {
    content: "\f134";
}
.iconsax-arrow-left:before {
    content: "\f135";
}
.iconsax-arrow-right-1:before {
    content: "\f136";
}
.iconsax-arrow-right-2:before {
    content: "\f137";
}
.iconsax-arrow-right-3:before {
    content: "\f138";
}
.iconsax-arrow-right-4:before {
    content: "\f139";
}
.iconsax-arrow-right:before {
    content: "\f13a";
}
.iconsax-arrow-square-down:before {
    content: "\f13b";
}
.iconsax-arrow-square-left:before {
    content: "\f13c";
}
.iconsax-arrow-square-up:before {
    content: "\f13d";
}
.iconsax-arrow-square:before {
    content: "\f13e";
}
.iconsax-arrow-swap-horizontal:before {
    content: "\f13f";
}
.iconsax-arrow-swap:before {
    content: "\f140";
}
.iconsax-arrow-up-1:before {
    content: "\f141";
}
.iconsax-arrow-up-2:before {
    content: "\f142";
}
.iconsax-arrow-up-3:before {
    content: "\f143";
}
.iconsax-arrow-up:before {
    content: "\f144";
}
.iconsax-arrow:before {
    content: "\f145";
}
.iconsax-attach-circle:before {
    content: "\f146";
}
.iconsax-attach-square:before {
    content: "\f147";
}
.iconsax-audio-square:before {
    content: "\f148";
}
.iconsax-augur-rep:before {
    content: "\f149";
}
.iconsax-autobrightness:before {
    content: "\f14a";
}
.iconsax-autonio-niox:before {
    content: "\f14b";
}
.iconsax-avalanche-avax:before {
    content: "\f14c";
}
.iconsax-award:before {
    content: "\f14d";
}
.iconsax-back-square:before {
    content: "\f14e";
}
.iconsax-backward-10-seconds:before {
    content: "\f14f";
}
.iconsax-backward-15-seconds:before {
    content: "\f150";
}
.iconsax-backward-5-seconds:before {
    content: "\f151";
}
.iconsax-backward-item:before {
    content: "\f152";
}
.iconsax-backward:before {
    content: "\f153";
}
.iconsax-bag-2:before {
    content: "\f154";
}
.iconsax-bag-cross-1:before {
    content: "\f155";
}
.iconsax-bag-cross:before {
    content: "\f156";
}
.iconsax-bag-happy:before {
    content: "\f157";
}
.iconsax-bag-tick-2:before {
    content: "\f158";
}
.iconsax-bag-tick:before {
    content: "\f159";
}
.iconsax-bag-timer:before {
    content: "\f15a";
}
.iconsax-bag:before {
    content: "\f15b";
}
.iconsax-bank:before {
    content: "\f15c";
}
.iconsax-barcode:before {
    content: "\f15d";
}
.iconsax-battery-charging:before {
    content: "\f15e";
}
.iconsax-battery-disable:before {
    content: "\f15f";
}
.iconsax-battery-empty-1:before {
    content: "\f160";
}
.iconsax-battery-empty:before {
    content: "\f161";
}
.iconsax-battery-full:before {
    content: "\f162";
}
.iconsax-battery-half:before {
    content: "\f163";
}
.iconsax-be:before {
    content: "\f164";
}
.iconsax-bezier:before {
    content: "\f165";
}
.iconsax-bill:before {
    content: "\f166";
}
.iconsax-binance-coin-bnb:before {
    content: "\f167";
}
.iconsax-binance-usd-busd:before {
    content: "\f168";
}
.iconsax-bitcoin-btc:before {
    content: "\f169";
}
.iconsax-bitcoin-card:before {
    content: "\f16a";
}
.iconsax-bitcoin-convert:before {
    content: "\f16b";
}
.iconsax-bitcoin-refresh:before {
    content: "\f16c";
}
.iconsax-blend-2:before {
    content: "\f16d";
}
.iconsax-blend:before {
    content: "\f16e";
}
.iconsax-blogger:before {
    content: "\f16f";
}
.iconsax-bluetooth-2:before {
    content: "\f170";
}
.iconsax-bluetooth-circle:before {
    content: "\f171";
}
.iconsax-bluetooth-rectangle:before {
    content: "\f172";
}
.iconsax-bluetooth:before {
    content: "\f173";
}
.iconsax-blur:before {
    content: "\f174";
}
.iconsax-book-1:before {
    content: "\f175";
}
.iconsax-book-saved:before {
    content: "\f176";
}
.iconsax-book-square:before {
    content: "\f177";
}
.iconsax-book:before {
    content: "\f178";
}
.iconsax-bookmark-2:before {
    content: "\f179";
}
.iconsax-bookmark:before {
    content: "\f17a";
}
.iconsax-bootstrap:before {
    content: "\f17b";
}
.iconsax-box-1:before {
    content: "\f17c";
}
.iconsax-box-2:before {
    content: "\f17d";
}
.iconsax-box-add:before {
    content: "\f17e";
}
.iconsax-box-remove:before {
    content: "\f17f";
}
.iconsax-box-search:before {
    content: "\f180";
}
.iconsax-box-tick:before {
    content: "\f181";
}
.iconsax-box-time:before {
    content: "\f182";
}
.iconsax-box:before {
    content: "\f183";
}
.iconsax-briefcase:before {
    content: "\f184";
}
.iconsax-brifecase-cross:before {
    content: "\f185";
}
.iconsax-brifecase-tick:before {
    content: "\f186";
}
.iconsax-brifecase-timer:before {
    content: "\f187";
}
.iconsax-broom:before {
    content: "\f188";
}
.iconsax-brush-1:before {
    content: "\f189";
}
.iconsax-brush-2:before {
    content: "\f18a";
}
.iconsax-brush-3:before {
    content: "\f18b";
}
.iconsax-brush-4:before {
    content: "\f18c";
}
.iconsax-brush:before {
    content: "\f18d";
}
.iconsax-bubble:before {
    content: "\f18e";
}
.iconsax-bucket-circle:before {
    content: "\f18f";
}
.iconsax-bucket-square:before {
    content: "\f190";
}
.iconsax-bucket:before {
    content: "\f191";
}
.iconsax-building-3:before {
    content: "\f192";
}
.iconsax-building-4:before {
    content: "\f193";
}
.iconsax-building:before {
    content: "\f194";
}
.iconsax-buildings-2:before {
    content: "\f195";
}
.iconsax-buildings:before {
    content: "\f196";
}
.iconsax-buliding:before {
    content: "\f197";
}
.iconsax-bus:before {
    content: "\f198";
}
.iconsax-buy-crypto:before {
    content: "\f199";
}
.iconsax-cake:before {
    content: "\f19a";
}
.iconsax-calculator:before {
    content: "\f19b";
}
.iconsax-calendar-1:before {
    content: "\f19c";
}
.iconsax-calendar-2:before {
    content: "\f19d";
}
.iconsax-calendar-add:before {
    content: "\f19e";
}
.iconsax-calendar-circle:before {
    content: "\f19f";
}
.iconsax-calendar-edit:before {
    content: "\f1a0";
}
.iconsax-calendar-remove:before {
    content: "\f1a1";
}
.iconsax-calendar-search:before {
    content: "\f1a2";
}
.iconsax-calendar-tick:before {
    content: "\f1a3";
}
.iconsax-calendar:before {
    content: "\f1a4";
}
.iconsax-call-add:before {
    content: "\f1a5";
}
.iconsax-call-calling:before {
    content: "\f1a6";
}
.iconsax-call-incoming:before {
    content: "\f1a7";
}
.iconsax-call-minus:before {
    content: "\f1a8";
}
.iconsax-call-outgoing:before {
    content: "\f1a9";
}
.iconsax-call-received:before {
    content: "\f1aa";
}
.iconsax-call-remove:before {
    content: "\f1ab";
}
.iconsax-call-slash:before {
    content: "\f1ac";
}
.iconsax-call:before {
    content: "\f1ad";
}
.iconsax-camera-slash:before {
    content: "\f1ae";
}
.iconsax-camera:before {
    content: "\f1af";
}
.iconsax-candle-2:before {
    content: "\f1b0";
}
.iconsax-candle:before {
    content: "\f1b1";
}
.iconsax-car:before {
    content: "\f1b2";
}
.iconsax-card-add:before {
    content: "\f1b3";
}
.iconsax-card-coin:before {
    content: "\f1b4";
}
.iconsax-card-edit:before {
    content: "\f1b5";
}
.iconsax-card-pos:before {
    content: "\f1b6";
}
.iconsax-card-receive:before {
    content: "\f1b7";
}
.iconsax-card-remove-1:before {
    content: "\f1b8";
}
.iconsax-card-remove:before {
    content: "\f1b9";
}
.iconsax-card-send:before {
    content: "\f1ba";
}
.iconsax-card-slash:before {
    content: "\f1bb";
}
.iconsax-card-tick-1:before {
    content: "\f1bc";
}
.iconsax-card-tick:before {
    content: "\f1bd";
}
.iconsax-card:before {
    content: "\f1be";
}
.iconsax-cardano-ada:before {
    content: "\f1bf";
}
.iconsax-cards:before {
    content: "\f1c0";
}
.iconsax-category-2:before {
    content: "\f1c1";
}
.iconsax-category:before {
    content: "\f1c2";
}
.iconsax-cd:before {
    content: "\f1c3";
}
.iconsax-celo-celo:before {
    content: "\f1c4";
}
.iconsax-celsius-cel:before {
    content: "\f1c5";
}
.iconsax-chainlink-link:before {
    content: "\f1c6";
}
.iconsax-chart-1:before {
    content: "\f1c7";
}
.iconsax-chart-2:before {
    content: "\f1c8";
}
.iconsax-chart-21:before {
    content: "\f1c9";
}
.iconsax-chart-3:before {
    content: "\f1ca";
}
.iconsax-chart-fail:before {
    content: "\f1cb";
}
.iconsax-chart-square:before {
    content: "\f1cc";
}
.iconsax-chart-success:before {
    content: "\f1cd";
}
.iconsax-chart:before {
    content: "\f1ce";
}
.iconsax-check:before {
    content: "\f1cf";
}
.iconsax-chrome:before {
    content: "\f1d0";
}
.iconsax-civic-cvc:before {
    content: "\f1d1";
}
.iconsax-clipboard-close:before {
    content: "\f1d2";
}
.iconsax-clipboard-export:before {
    content: "\f1d3";
}
.iconsax-clipboard-import:before {
    content: "\f1d4";
}
.iconsax-clipboard-text:before {
    content: "\f1d5";
}
.iconsax-clipboard-tick:before {
    content: "\f1d6";
}
.iconsax-clipboard:before {
    content: "\f1d7";
}
.iconsax-clock-1:before {
    content: "\f1d8";
}
.iconsax-clock:before {
    content: "\f1d9";
}
.iconsax-close-circle:before {
    content: "\f1da";
}
.iconsax-close-square:before {
    content: "\f1db";
}
.iconsax-cloud-add:before {
    content: "\f1dc";
}
.iconsax-cloud-change:before {
    content: "\f1dd";
}
.iconsax-cloud-connection:before {
    content: "\f1de";
}
.iconsax-cloud-cross:before {
    content: "\f1df";
}
.iconsax-cloud-drizzle:before {
    content: "\f1e0";
}
.iconsax-cloud-fog:before {
    content: "\f1e1";
}
.iconsax-cloud-lightning:before {
    content: "\f1e2";
}
.iconsax-cloud-minus:before {
    content: "\f1e3";
}
.iconsax-cloud-notif:before {
    content: "\f1e4";
}
.iconsax-cloud-plus:before {
    content: "\f1e5";
}
.iconsax-cloud-remove:before {
    content: "\f1e6";
}
.iconsax-cloud-snow:before {
    content: "\f1e7";
}
.iconsax-cloud-sunny:before {
    content: "\f1e8";
}
.iconsax-cloud:before {
    content: "\f1e9";
}
.iconsax-code-1:before {
    content: "\f1ea";
}
.iconsax-code-circle:before {
    content: "\f1eb";
}
.iconsax-code:before {
    content: "\f1ec";
}
.iconsax-coffee:before {
    content: "\f1ed";
}
.iconsax-coin-1:before {
    content: "\f1ee";
}
.iconsax-coin:before {
    content: "\f1ef";
}
.iconsax-color-swatch:before {
    content: "\f1f0";
}
.iconsax-colorfilter:before {
    content: "\f1f1";
}
.iconsax-colors-square:before {
    content: "\f1f2";
}
.iconsax-command-square:before {
    content: "\f1f3";
}
.iconsax-command:before {
    content: "\f1f4";
}
.iconsax-component:before {
    content: "\f1f5";
}
.iconsax-computing:before {
    content: "\f1f6";
}
.iconsax-convert-3d-cube:before {
    content: "\f1f7";
}
.iconsax-convert-card:before {
    content: "\f1f8";
}
.iconsax-convert:before {
    content: "\f1f9";
}
.iconsax-convertshape-2:before {
    content: "\f1fa";
}
.iconsax-convertshape:before {
    content: "\f1fb";
}
.iconsax-copy-success:before {
    content: "\f1fc";
}
.iconsax-copy:before {
    content: "\f1fd";
}
.iconsax-copyright:before {
    content: "\f1fe";
}
.iconsax-courthouse:before {
    content: "\f1ff";
}
.iconsax-cpu-charge:before {
    content: "\f200";
}
.iconsax-cpu-setting:before {
    content: "\f201";
}
.iconsax-cpu:before {
    content: "\f202";
}
.iconsax-creative-commons:before {
    content: "\f203";
}
.iconsax-crop:before {
    content: "\f204";
}
.iconsax-crown-1:before {
    content: "\f205";
}
.iconsax-crown:before {
    content: "\f206";
}
.iconsax-cup:before {
    content: "\f207";
}
.iconsax-dai-dai:before {
    content: "\f208";
}
.iconsax-danger:before {
    content: "\f209";
}
.iconsax-dash-dash:before {
    content: "\f20a";
}
.iconsax-data-2:before {
    content: "\f20b";
}
.iconsax-data:before {
    content: "\f20c";
}
.iconsax-decred-dcr:before {
    content: "\f20d";
}
.iconsax-dent-dent:before {
    content: "\f20e";
}
.iconsax-designtools:before {
    content: "\f20f";
}
.iconsax-device-message:before {
    content: "\f210";
}
.iconsax-devices-1:before {
    content: "\f211";
}
.iconsax-devices:before {
    content: "\f212";
}
.iconsax-diagram:before {
    content: "\f213";
}
.iconsax-diamonds:before {
    content: "\f214";
}
.iconsax-direct-down:before {
    content: "\f215";
}
.iconsax-direct-inbox:before {
    content: "\f216";
}
.iconsax-direct-left:before {
    content: "\f217";
}
.iconsax-direct-normal:before {
    content: "\f218";
}
.iconsax-direct-notification:before {
    content: "\f219";
}
.iconsax-direct-right:before {
    content: "\f21a";
}
.iconsax-direct-send:before {
    content: "\f21b";
}
.iconsax-direct-up:before {
    content: "\f21c";
}
.iconsax-direct:before {
    content: "\f21d";
}
.iconsax-directbox-default:before {
    content: "\f21e";
}
.iconsax-directbox-notif:before {
    content: "\f21f";
}
.iconsax-directbox-receive:before {
    content: "\f220";
}
.iconsax-directbox-send:before {
    content: "\f221";
}
.iconsax-discount-circle:before {
    content: "\f222";
}
.iconsax-discount-shape:before {
    content: "\f223";
}
.iconsax-discover-1:before {
    content: "\f224";
}
.iconsax-discover:before {
    content: "\f225";
}
.iconsax-dislike:before {
    content: "\f226";
}
.iconsax-document-1:before {
    content: "\f227";
}
.iconsax-document-cloud:before {
    content: "\f228";
}
.iconsax-document-code-2:before {
    content: "\f229";
}
.iconsax-document-code:before {
    content: "\f22a";
}
.iconsax-document-copy:before {
    content: "\f22b";
}
.iconsax-document-download:before {
    content: "\f22c";
}
.iconsax-document-favorite:before {
    content: "\f22d";
}
.iconsax-document-filter:before {
    content: "\f22e";
}
.iconsax-document-forward:before {
    content: "\f22f";
}
.iconsax-document-like:before {
    content: "\f230";
}
.iconsax-document-normal:before {
    content: "\f231";
}
.iconsax-document-previous:before {
    content: "\f232";
}
.iconsax-document-sketch:before {
    content: "\f233";
}
.iconsax-document-text-1:before {
    content: "\f234";
}
.iconsax-document-text:before {
    content: "\f235";
}
.iconsax-document-upload:before {
    content: "\f236";
}
.iconsax-document:before {
    content: "\f237";
}
.iconsax-dollar-circle:before {
    content: "\f238";
}
.iconsax-dollar-square:before {
    content: "\f239";
}
.iconsax-dribbble:before {
    content: "\f23a";
}
.iconsax-driver-2:before {
    content: "\f23b";
}
.iconsax-driver-refresh:before {
    content: "\f23c";
}
.iconsax-driver:before {
    content: "\f23d";
}
.iconsax-driving:before {
    content: "\f23e";
}
.iconsax-dropbox:before {
    content: "\f23f";
}
.iconsax-edit-2:before {
    content: "\f240";
}
.iconsax-edit:before {
    content: "\f241";
}
.iconsax-educare-ekt:before {
    content: "\f242";
}
.iconsax-electricity:before {
    content: "\f243";
}
.iconsax-element-2:before {
    content: "\f244";
}
.iconsax-element-3:before {
    content: "\f245";
}
.iconsax-element-4:before {
    content: "\f246";
}
.iconsax-element-equal:before {
    content: "\f247";
}
.iconsax-element-plus:before {
    content: "\f248";
}
.iconsax-emercoin-emc:before {
    content: "\f249";
}
.iconsax-emoji-happy:before {
    content: "\f24a";
}
.iconsax-emoji-normal:before {
    content: "\f24b";
}
.iconsax-emoji-sad:before {
    content: "\f24c";
}
.iconsax-empty-wallet-add:before {
    content: "\f24d";
}
.iconsax-empty-wallet-change:before {
    content: "\f24e";
}
.iconsax-empty-wallet-remove:before {
    content: "\f24f";
}
.iconsax-empty-wallet-tick:before {
    content: "\f250";
}
.iconsax-empty-wallet-time:before {
    content: "\f251";
}
.iconsax-empty-wallet:before {
    content: "\f252";
}
.iconsax-enjin-coin-enj:before {
    content: "\f253";
}
.iconsax-eos-eos:before {
    content: "\f254";
}
.iconsax-eraser-1:before {
    content: "\f255";
}
.iconsax-eraser:before {
    content: "\f256";
}
.iconsax-ethereum-classic-etc:before {
    content: "\f257";
}
.iconsax-ethereum-eth:before {
    content: "\f258";
}
.iconsax-export-1:before {
    content: "\f259";
}
.iconsax-export-2:before {
    content: "\f25a";
}
.iconsax-export-3:before {
    content: "\f25b";
}
.iconsax-export:before {
    content: "\f25c";
}
.iconsax-external-drive:before {
    content: "\f25d";
}
.iconsax-eye-slash:before {
    content: "\f25e";
}
.iconsax-eye:before {
    content: "\f25f";
}
.iconsax-facebook:before {
    content: "\f260";
}
.iconsax-fatrows:before {
    content: "\f261";
}
.iconsax-favorite-chart:before {
    content: "\f262";
}
.iconsax-figma-1:before {
    content: "\f263";
}
.iconsax-figma:before {
    content: "\f264";
}
.iconsax-filter-add:before {
    content: "\f265";
}
.iconsax-filter-edit:before {
    content: "\f266";
}
.iconsax-filter-remove:before {
    content: "\f267";
}
.iconsax-filter-search:before {
    content: "\f268";
}
.iconsax-filter-square:before {
    content: "\f269";
}
.iconsax-filter-tick:before {
    content: "\f26a";
}
.iconsax-filter:before {
    content: "\f26b";
}
.iconsax-finger-cricle:before {
    content: "\f26c";
}
.iconsax-finger-scan:before {
    content: "\f26d";
}
.iconsax-firstline:before {
    content: "\f26e";
}
.iconsax-flag-2:before {
    content: "\f26f";
}
.iconsax-flag:before {
    content: "\f270";
}
.iconsax-flash-1:before {
    content: "\f271";
}
.iconsax-flash-circle-1:before {
    content: "\f272";
}
.iconsax-flash-circle:before {
    content: "\f273";
}
.iconsax-flash-slash:before {
    content: "\f274";
}
.iconsax-flash:before {
    content: "\f275";
}
.iconsax-folder-2:before {
    content: "\f276";
}
.iconsax-folder-add:before {
    content: "\f277";
}
.iconsax-folder-cloud:before {
    content: "\f278";
}
.iconsax-folder-connection:before {
    content: "\f279";
}
.iconsax-folder-cross:before {
    content: "\f27a";
}
.iconsax-folder-favorite:before {
    content: "\f27b";
}
.iconsax-folder-minus:before {
    content: "\f27c";
}
.iconsax-folder-open:before {
    content: "\f27d";
}
.iconsax-folder:before {
    content: "\f27e";
}
.iconsax-forbidden-2:before {
    content: "\f27f";
}
.iconsax-forbidden:before {
    content: "\f280";
}
.iconsax-format-circle:before {
    content: "\f281";
}
.iconsax-format-square:before {
    content: "\f282";
}
.iconsax-forward-10-seconds:before {
    content: "\f283";
}
.iconsax-forward-15-seconds:before {
    content: "\f284";
}
.iconsax-forward-5-seconds:before {
    content: "\f285";
}
.iconsax-forward-item:before {
    content: "\f286";
}
.iconsax-forward-square:before {
    content: "\f287";
}
.iconsax-forward:before {
    content: "\f288";
}
.iconsax-frame-1:before {
    content: "\f289";
}
.iconsax-frame-2:before {
    content: "\f28a";
}
.iconsax-frame-3:before {
    content: "\f28b";
}
.iconsax-frame-4:before {
    content: "\f28c";
}
.iconsax-frame:before {
    content: "\f28d";
}
.iconsax-framer:before {
    content: "\f28e";
}
.iconsax-ftx-token-ftt:before {
    content: "\f28f";
}
.iconsax-gallery-add:before {
    content: "\f290";
}
.iconsax-gallery-edit:before {
    content: "\f291";
}
.iconsax-gallery-export:before {
    content: "\f292";
}
.iconsax-gallery-favorite:before {
    content: "\f293";
}
.iconsax-gallery-import:before {
    content: "\f294";
}
.iconsax-gallery-remove:before {
    content: "\f295";
}
.iconsax-gallery-slash:before {
    content: "\f296";
}
.iconsax-gallery-tick:before {
    content: "\f297";
}
.iconsax-gallery:before {
    content: "\f298";
}
.iconsax-game:before {
    content: "\f299";
}
.iconsax-gameboy:before {
    content: "\f29a";
}
.iconsax-gas-station:before {
    content: "\f29b";
}
.iconsax-gemini-2:before {
    content: "\f29c";
}
.iconsax-gemini:before {
    content: "\f29d";
}
.iconsax-ghost:before {
    content: "\f29e";
}
.iconsax-gift:before {
    content: "\f29f";
}
.iconsax-glass-1:before {
    content: "\f2a0";
}
.iconsax-glass:before {
    content: "\f2a1";
}
.iconsax-global-edit:before {
    content: "\f2a2";
}
.iconsax-global-refresh:before {
    content: "\f2a3";
}
.iconsax-global-search:before {
    content: "\f2a4";
}
.iconsax-global:before {
    content: "\f2a5";
}
.iconsax-google-drive:before {
    content: "\f2a6";
}
.iconsax-google-paly:before {
    content: "\f2a7";
}
.iconsax-google:before {
    content: "\f2a8";
}
.iconsax-gps-slash:before {
    content: "\f2a9";
}
.iconsax-gps:before {
    content: "\f2aa";
}
.iconsax-grammerly:before {
    content: "\f2ab";
}
.iconsax-graph:before {
    content: "\f2ac";
}
.iconsax-grid-1:before {
    content: "\f2ad";
}
.iconsax-grid-2:before {
    content: "\f2ae";
}
.iconsax-grid-3:before {
    content: "\f2af";
}
.iconsax-grid-4:before {
    content: "\f2b0";
}
.iconsax-grid-5:before {
    content: "\f2b1";
}
.iconsax-grid-6:before {
    content: "\f2b2";
}
.iconsax-grid-7:before {
    content: "\f2b3";
}
.iconsax-grid-8:before {
    content: "\f2b4";
}
.iconsax-grid-9:before {
    content: "\f2b5";
}
.iconsax-grid-edit:before {
    content: "\f2b6";
}
.iconsax-grid-eraser:before {
    content: "\f2b7";
}
.iconsax-grid-lock:before {
    content: "\f2b8";
}
.iconsax-happyemoji:before {
    content: "\f2b9";
}
.iconsax-harmony-one:before {
    content: "\f2ba";
}
.iconsax-hashtag-1:before {
    content: "\f2bb";
}
.iconsax-hashtag-down:before {
    content: "\f2bc";
}
.iconsax-hashtag-up:before {
    content: "\f2bd";
}
.iconsax-hashtag:before {
    content: "\f2be";
}
.iconsax-headphone:before {
    content: "\f2bf";
}
.iconsax-headphones:before {
    content: "\f2c0";
}
.iconsax-health:before {
    content: "\f2c1";
}
.iconsax-heart-add:before {
    content: "\f2c2";
}
.iconsax-heart-circle:before {
    content: "\f2c3";
}
.iconsax-heart-edit:before {
    content: "\f2c4";
}
.iconsax-heart-remove:before {
    content: "\f2c5";
}
.iconsax-heart-search:before {
    content: "\f2c6";
}
.iconsax-heart-slash:before {
    content: "\f2c7";
}
.iconsax-heart-tick:before {
    content: "\f2c8";
}
.iconsax-heart:before {
    content: "\f2c9";
}
.iconsax-hedera-hashgraph-hbar:before {
    content: "\f2ca";
}
.iconsax-hex-hex:before {
    content: "\f2cb";
}
.iconsax-hierarchy-2:before {
    content: "\f2cc";
}
.iconsax-hierarchy-3:before {
    content: "\f2cd";
}
.iconsax-hierarchy-square-2:before {
    content: "\f2ce";
}
.iconsax-hierarchy-square-3:before {
    content: "\f2cf";
}
.iconsax-hierarchy-square:before {
    content: "\f2d0";
}
.iconsax-hierarchy:before {
    content: "\f2d1";
}
.iconsax-home-1:before {
    content: "\f2d2";
}
.iconsax-home-2:before {
    content: "\f2d3";
}
.iconsax-home-hashtag:before {
    content: "\f2d4";
}
.iconsax-home-trend-down:before {
    content: "\f2d5";
}
.iconsax-home-trend-up:before {
    content: "\f2d6";
}
.iconsax-home-wifi:before {
    content: "\f2d7";
}
.iconsax-home:before {
    content: "\f2d8";
}
.iconsax-hospital:before {
    content: "\f2d9";
}
.iconsax-house-2:before {
    content: "\f2da";
}
.iconsax-house:before {
    content: "\f2db";
}
.iconsax-html-3:before {
    content: "\f2dc";
}
.iconsax-html-5:before {
    content: "\f2dd";
}
.iconsax-huobi-token-ht:before {
    content: "\f2de";
}
.iconsax-iconsax-1:before {
    content: "\f2df";
}
.iconsax-iconsax-2:before {
    content: "\f2e0";
}
.iconsax-iconsax-icx:before {
    content: "\f2e1";
}
.iconsax-iconsax:before {
    content: "\f2e2";
}
.iconsax-illustrator:before {
    content: "\f2e3";
}
.iconsax-image:before {
    content: "\f2e4";
}
.iconsax-import-1:before {
    content: "\f2e5";
}
.iconsax-import-2:before {
    content: "\f2e6";
}
.iconsax-import:before {
    content: "\f2e7";
}
.iconsax-info-circle:before {
    content: "\f2e8";
}
.iconsax-information:before {
    content: "\f2e9";
}
.iconsax-instagram:before {
    content: "\f2ea";
}
.iconsax-iost-iost:before {
    content: "\f2eb";
}
.iconsax-java-script:before {
    content: "\f2ec";
}
.iconsax-js:before {
    content: "\f2ed";
}
.iconsax-judge:before {
    content: "\f2ee";
}
.iconsax-kanban:before {
    content: "\f2ef";
}
.iconsax-key-square:before {
    content: "\f2f0";
}
.iconsax-key:before {
    content: "\f2f1";
}
.iconsax-keyboard-open:before {
    content: "\f2f2";
}
.iconsax-keyboard:before {
    content: "\f2f3";
}
.iconsax-kyber-network-knc:before {
    content: "\f2f4";
}
.iconsax-lamp-1:before {
    content: "\f2f5";
}
.iconsax-lamp-charge:before {
    content: "\f2f6";
}
.iconsax-lamp-on:before {
    content: "\f2f7";
}
.iconsax-lamp-slash:before {
    content: "\f2f8";
}
.iconsax-lamp:before {
    content: "\f2f9";
}
.iconsax-language-circle:before {
    content: "\f2fa";
}
.iconsax-language-square:before {
    content: "\f2fb";
}
.iconsax-layer:before {
    content: "\f2fc";
}
.iconsax-level:before {
    content: "\f2fd";
}
.iconsax-lifebuoy:before {
    content: "\f2fe";
}
.iconsax-like-1:before {
    content: "\f2ff";
}
.iconsax-like-dislike:before {
    content: "\f300";
}
.iconsax-like-shapes:before {
    content: "\f301";
}
.iconsax-like-tag:before {
    content: "\f302";
}
.iconsax-like:before {
    content: "\f303";
}
.iconsax-Line:before {
    content: "\f304";
}
.iconsax-link-1:before {
    content: "\f305";
}
.iconsax-link-2:before {
    content: "\f306";
}
.iconsax-link-21:before {
    content: "\f307";
}
.iconsax-link-circle:before {
    content: "\f308";
}
.iconsax-link-square:before {
    content: "\f309";
}
.iconsax-link:before {
    content: "\f30a";
}
.iconsax-litecoinltc:before {
    content: "\f30b";
}
.iconsax-location-add:before {
    content: "\f30c";
}
.iconsax-location-cross:before {
    content: "\f30d";
}
.iconsax-location-minus:before {
    content: "\f30e";
}
.iconsax-location-slash:before {
    content: "\f30f";
}
.iconsax-location-tick:before {
    content: "\f310";
}
.iconsax-location:before {
    content: "\f311";
}
.iconsax-lock-1:before {
    content: "\f312";
}
.iconsax-lock-circle:before {
    content: "\f313";
}
.iconsax-lock-slash:before {
    content: "\f314";
}
.iconsax-lock:before {
    content: "\f315";
}
.iconsax-login-1:before {
    content: "\f316";
}
.iconsax-login:before {
    content: "\f317";
}
.iconsax-logout-1:before {
    content: "\f318";
}
.iconsax-logout:before {
    content: "\f319";
}
.iconsax-lovely:before {
    content: "\f31a";
}
.iconsax-magic-star:before {
    content: "\f31b";
}
.iconsax-magicpen:before {
    content: "\f31c";
}
.iconsax-main-component:before {
    content: "\f31d";
}
.iconsax-maker-mkr:before {
    content: "\f31e";
}
.iconsax-man:before {
    content: "\f31f";
}
.iconsax-map-1:before {
    content: "\f320";
}
.iconsax-map:before {
    content: "\f321";
}
.iconsax-mask-1:before {
    content: "\f322";
}
.iconsax-mask-2:before {
    content: "\f323";
}
.iconsax-mask:before {
    content: "\f324";
}
.iconsax-math:before {
    content: "\f325";
}
.iconsax-maximize-1:before {
    content: "\f326";
}
.iconsax-maximize-2:before {
    content: "\f327";
}
.iconsax-maximize-21:before {
    content: "\f328";
}
.iconsax-maximize-3:before {
    content: "\f329";
}
.iconsax-maximize-4:before {
    content: "\f32a";
}
.iconsax-maximize-circle:before {
    content: "\f32b";
}
.iconsax-maximize:before {
    content: "\f32c";
}
.iconsax-medal-star:before {
    content: "\f32d";
}
.iconsax-medal:before {
    content: "\f32e";
}
.iconsax-menu-1:before {
    content: "\f32f";
}
.iconsax-menu-board:before {
    content: "\f330";
}
.iconsax-menu:before {
    content: "\f331";
}
.iconsax-message-2:before {
    content: "\f332";
}
.iconsax-message-add-1:before {
    content: "\f333";
}
.iconsax-message-add:before {
    content: "\f334";
}
.iconsax-message-circle:before {
    content: "\f335";
}
.iconsax-message-edit:before {
    content: "\f336";
}
.iconsax-message-favorite:before {
    content: "\f337";
}
.iconsax-message-minus:before {
    content: "\f338";
}
.iconsax-message-notif:before {
    content: "\f339";
}
.iconsax-message-programming:before {
    content: "\f33a";
}
.iconsax-message-question:before {
    content: "\f33b";
}
.iconsax-message-remove:before {
    content: "\f33c";
}
.iconsax-message-search:before {
    content: "\f33d";
}
.iconsax-message-square:before {
    content: "\f33e";
}
.iconsax-message-text-1:before {
    content: "\f33f";
}
.iconsax-message-text:before {
    content: "\f340";
}
.iconsax-message-tick:before {
    content: "\f341";
}
.iconsax-message-time:before {
    content: "\f342";
}
.iconsax-message:before {
    content: "\f343";
}
.iconsax-messages-1:before {
    content: "\f344";
}
.iconsax-messages-2:before {
    content: "\f345";
}
.iconsax-messages-3:before {
    content: "\f346";
}
.iconsax-messages:before {
    content: "\f347";
}
.iconsax-messenger:before {
    content: "\f348";
}
.iconsax-microphone-2:before {
    content: "\f349";
}
.iconsax-microphone-slash-1:before {
    content: "\f34a";
}
.iconsax-microphone-slash:before {
    content: "\f34b";
}
.iconsax-microphone:before {
    content: "\f34c";
}
.iconsax-microscope:before {
    content: "\f34d";
}
.iconsax-milk:before {
    content: "\f34e";
}
.iconsax-mini-music-sqaure:before {
    content: "\f34f";
}
.iconsax-minus-cirlce:before {
    content: "\f350";
}
.iconsax-minus-square:before {
    content: "\f351";
}
.iconsax-minus:before {
    content: "\f352";
}
.iconsax-mirror:before {
    content: "\f353";
}
.iconsax-mirroring-screen:before {
    content: "\f354";
}
.iconsax-mobile-programming:before {
    content: "\f355";
}
.iconsax-mobile:before {
    content: "\f356";
}
.iconsax-monero-xmr:before {
    content: "\f357";
}
.iconsax-money-2:before {
    content: "\f358";
}
.iconsax-money-3:before {
    content: "\f359";
}
.iconsax-money-4:before {
    content: "\f35a";
}
.iconsax-money-add:before {
    content: "\f35b";
}
.iconsax-money-change:before {
    content: "\f35c";
}
.iconsax-money-forbidden:before {
    content: "\f35d";
}
.iconsax-money-recive:before {
    content: "\f35e";
}
.iconsax-money-remove:before {
    content: "\f35f";
}
.iconsax-money-send:before {
    content: "\f360";
}
.iconsax-money-tick:before {
    content: "\f361";
}
.iconsax-money-time:before {
    content: "\f362";
}
.iconsax-money:before {
    content: "\f363";
}
.iconsax-moneys:before {
    content: "\f364";
}
.iconsax-monitor-mobbile:before {
    content: "\f365";
}
.iconsax-monitor-recorder:before {
    content: "\f366";
}
.iconsax-monitor:before {
    content: "\f367";
}
.iconsax-moon:before {
    content: "\f368";
}
.iconsax-more-2:before {
    content: "\f369";
}
.iconsax-more-circle:before {
    content: "\f36a";
}
.iconsax-more-square:before {
    content: "\f36b";
}
.iconsax-more:before {
    content: "\f36c";
}
.iconsax-mouse-1:before {
    content: "\f36d";
}
.iconsax-mouse-circle:before {
    content: "\f36e";
}
.iconsax-mouse-square:before {
    content: "\f36f";
}
.iconsax-mouse:before {
    content: "\f370";
}
.iconsax-music-circle:before {
    content: "\f371";
}
.iconsax-music-dashboard:before {
    content: "\f372";
}
.iconsax-music-filter:before {
    content: "\f373";
}
.iconsax-music-library-2:before {
    content: "\f374";
}
.iconsax-music-play:before {
    content: "\f375";
}
.iconsax-music-playlist:before {
    content: "\f376";
}
.iconsax-music-square-add:before {
    content: "\f377";
}
.iconsax-music-square-remove:before {
    content: "\f378";
}
.iconsax-music-square-search:before {
    content: "\f379";
}
.iconsax-music-square:before {
    content: "\f37a";
}
.iconsax-music:before {
    content: "\f37b";
}
.iconsax-musicnote:before {
    content: "\f37c";
}
.iconsax-nebulas-nas:before {
    content: "\f37d";
}
.iconsax-nem-xem:before {
    content: "\f37e";
}
.iconsax-nexo-nexo:before {
    content: "\f37f";
}
.iconsax-next:before {
    content: "\f380";
}
.iconsax-note-1:before {
    content: "\f381";
}
.iconsax-note-2:before {
    content: "\f382";
}
.iconsax-note-21:before {
    content: "\f383";
}
.iconsax-note-add:before {
    content: "\f384";
}
.iconsax-note-favorite:before {
    content: "\f385";
}
.iconsax-note-remove:before {
    content: "\f386";
}
.iconsax-note-square:before {
    content: "\f387";
}
.iconsax-note-text:before {
    content: "\f388";
}
.iconsax-note:before {
    content: "\f389";
}
.iconsax-notification-1:before {
    content: "\f38a";
}
.iconsax-notification-bing:before {
    content: "\f38b";
}
.iconsax-notification-circle:before {
    content: "\f38c";
}
.iconsax-notification-favorite:before {
    content: "\f38d";
}
.iconsax-notification-status:before {
    content: "\f38e";
}
.iconsax-notification:before {
    content: "\f38f";
}
.iconsax-ocean-protocol-ocean:before {
    content: "\f390";
}
.iconsax-okb-okb:before {
    content: "\f391";
}
.iconsax-omega-circle:before {
    content: "\f392";
}
.iconsax-omega-square:before {
    content: "\f393";
}
.iconsax-ontology-ont:before {
    content: "\f394";
}
.iconsax-paintbucket:before {
    content: "\f395";
}
.iconsax-paperclip-2:before {
    content: "\f396";
}
.iconsax-paperclip:before {
    content: "\f397";
}
.iconsax-password-check:before {
    content: "\f398";
}
.iconsax-path-2:before {
    content: "\f399";
}
.iconsax-path-square:before {
    content: "\f39a";
}
.iconsax-path:before {
    content: "\f39b";
}
.iconsax-pause-circle:before {
    content: "\f39c";
}
.iconsax-pause:before {
    content: "\f39d";
}
.iconsax-paypal:before {
    content: "\f39e";
}
.iconsax-pen-add:before {
    content: "\f39f";
}
.iconsax-pen-close:before {
    content: "\f3a0";
}
.iconsax-pen-remove:before {
    content: "\f3a1";
}
.iconsax-pen-tool-2:before {
    content: "\f3a2";
}
.iconsax-pen-tool:before {
    content: "\f3a3";
}
.iconsax-people:before {
    content: "\f3a4";
}
.iconsax-percentage-circle:before {
    content: "\f3a5";
}
.iconsax-percentage-square:before {
    content: "\f3a6";
}
.iconsax-personalcard:before {
    content: "\f3a7";
}
.iconsax-pet:before {
    content: "\f3a8";
}
.iconsax-pharagraphspacing:before {
    content: "\f3a9";
}
.iconsax-photoshop:before {
    content: "\f3aa";
}
.iconsax-picture-frame:before {
    content: "\f3ab";
}
.iconsax-play-add:before {
    content: "\f3ac";
}
.iconsax-play-circle:before {
    content: "\f3ad";
}
.iconsax-play-cricle:before {
    content: "\f3ae";
}
.iconsax-play-remove:before {
    content: "\f3af";
}
.iconsax-play:before {
    content: "\f3b0";
}
.iconsax-polkadot-dot:before {
    content: "\f3b1";
}
.iconsax-polygon-matic:before {
    content: "\f3b2";
}
.iconsax-polyswarm-nct:before {
    content: "\f3b3";
}
.iconsax-presention-chart:before {
    content: "\f3b4";
}
.iconsax-previous:before {
    content: "\f3b5";
}
.iconsax-printer-slash:before {
    content: "\f3b6";
}
.iconsax-printer:before {
    content: "\f3b7";
}
.iconsax-profile-2user:before {
    content: "\f3b8";
}
.iconsax-profile-add:before {
    content: "\f3b9";
}
.iconsax-profile-circle:before {
    content: "\f3ba";
}
.iconsax-profile-delete:before {
    content: "\f3bb";
}
.iconsax-profile-remove:before {
    content: "\f3bc";
}
.iconsax-profile-tick:before {
    content: "\f3bd";
}
.iconsax-programming-arrow:before {
    content: "\f3be";
}
.iconsax-programming-arrows:before {
    content: "\f3bf";
}
.iconsax-python:before {
    content: "\f3c0";
}
.iconsax-quant-qnt:before {
    content: "\f3c1";
}
.iconsax-quote-down-circle:before {
    content: "\f3c2";
}
.iconsax-quote-down-square:before {
    content: "\f3c3";
}
.iconsax-quote-down:before {
    content: "\f3c4";
}
.iconsax-quote-up-circle:before {
    content: "\f3c5";
}
.iconsax-quote-up-square:before {
    content: "\f3c6";
}
.iconsax-quote-up:before {
    content: "\f3c7";
}
.iconsax-radar-1:before {
    content: "\f3c8";
}
.iconsax-radar-2:before {
    content: "\f3c9";
}
.iconsax-radar:before {
    content: "\f3ca";
}
.iconsax-radio:before {
    content: "\f3cb";
}
.iconsax-ram-2:before {
    content: "\f3cc";
}
.iconsax-ram:before {
    content: "\f3cd";
}
.iconsax-ranking-1:before {
    content: "\f3ce";
}
.iconsax-ranking:before {
    content: "\f3cf";
}
.iconsax-receipt-1:before {
    content: "\f3d0";
}
.iconsax-receipt-2-1:before {
    content: "\f3d1";
}
.iconsax-receipt-2:before {
    content: "\f3d2";
}
.iconsax-receipt-add:before {
    content: "\f3d3";
}
.iconsax-receipt-discount:before {
    content: "\f3d4";
}
.iconsax-receipt-disscount:before {
    content: "\f3d5";
}
.iconsax-receipt-edit:before {
    content: "\f3d6";
}
.iconsax-receipt-item:before {
    content: "\f3d7";
}
.iconsax-receipt-minus:before {
    content: "\f3d8";
}
.iconsax-receipt-search:before {
    content: "\f3d9";
}
.iconsax-receipt-square:before {
    content: "\f3da";
}
.iconsax-receipt-text:before {
    content: "\f3db";
}
.iconsax-receipt:before {
    content: "\f3dc";
}
.iconsax-receive-square-2:before {
    content: "\f3dd";
}
.iconsax-receive-square:before {
    content: "\f3de";
}
.iconsax-received:before {
    content: "\f3df";
}
.iconsax-record-circle:before {
    content: "\f3e0";
}
.iconsax-record:before {
    content: "\f3e1";
}
.iconsax-recovery-convert:before {
    content: "\f3e2";
}
.iconsax-redo:before {
    content: "\f3e3";
}
.iconsax-refresh-2:before {
    content: "\f3e4";
}
.iconsax-refresh-circle:before {
    content: "\f3e5";
}
.iconsax-refresh-left-square:before {
    content: "\f3e6";
}
.iconsax-refresh-right-square:before {
    content: "\f3e7";
}
.iconsax-refresh-square-2:before {
    content: "\f3e8";
}
.iconsax-refresh:before {
    content: "\f3e9";
}
.iconsax-repeat-circle:before {
    content: "\f3ea";
}
.iconsax-repeat:before {
    content: "\f3eb";
}
.iconsax-repeate-music:before {
    content: "\f3ec";
}
.iconsax-repeate-one:before {
    content: "\f3ed";
}
.iconsax-reserve:before {
    content: "\f3ee";
}
.iconsax-rotate-left-1:before {
    content: "\f3ef";
}
.iconsax-rotate-left:before {
    content: "\f3f0";
}
.iconsax-rotate-right-1:before {
    content: "\f3f1";
}
.iconsax-rotate-right:before {
    content: "\f3f2";
}
.iconsax-route-square:before {
    content: "\f3f3";
}
.iconsax-routing-2:before {
    content: "\f3f4";
}
.iconsax-routing:before {
    content: "\f3f5";
}
.iconsax-row-horizontal:before {
    content: "\f3f6";
}
.iconsax-row-vertical:before {
    content: "\f3f7";
}
.iconsax-ruler:before {
    content: "\f3f8";
}
.iconsax-rulerandpen:before {
    content: "\f3f9";
}
.iconsax-safe-home:before {
    content: "\f3fa";
}
.iconsax-sagittarius:before {
    content: "\f3fb";
}
.iconsax-save-2:before {
    content: "\f3fc";
}
.iconsax-save-add:before {
    content: "\f3fd";
}
.iconsax-save-minus:before {
    content: "\f3fe";
}
.iconsax-save-remove:before {
    content: "\f3ff";
}
.iconsax-scan-barcode:before {
    content: "\f400";
}
.iconsax-scan:before {
    content: "\f401";
}
.iconsax-scanner:before {
    content: "\f402";
}
.iconsax-scanning:before {
    content: "\f403";
}
.iconsax-scissor-1:before {
    content: "\f404";
}
.iconsax-scissor:before {
    content: "\f405";
}
.iconsax-screenmirroring:before {
    content: "\f406";
}
.iconsax-scroll:before {
    content: "\f407";
}
.iconsax-search-favorite-1:before {
    content: "\f408";
}
.iconsax-search-favorite:before {
    content: "\f409";
}
.iconsax-search-normal-1:before {
    content: "\f40a";
}
.iconsax-search-normal:before {
    content: "\f40b";
}
.iconsax-search-status-1:before {
    content: "\f40c";
}
.iconsax-search-status:before {
    content: "\f40d";
}
.iconsax-search-zoom-in-1:before {
    content: "\f40e";
}
.iconsax-search-zoom-in:before {
    content: "\f40f";
}
.iconsax-search-zoom-out-1:before {
    content: "\f410";
}
.iconsax-search-zoom-out:before {
    content: "\f411";
}
.iconsax-security-card:before {
    content: "\f412";
}
.iconsax-security-safe:before {
    content: "\f413";
}
.iconsax-security-time:before {
    content: "\f414";
}
.iconsax-security-user:before {
    content: "\f415";
}
.iconsax-security:before {
    content: "\f416";
}
.iconsax-send-1:before {
    content: "\f417";
}
.iconsax-send-2:before {
    content: "\f418";
}
.iconsax-send-sqaure-2:before {
    content: "\f419";
}
.iconsax-send-square:before {
    content: "\f41a";
}
.iconsax-send:before {
    content: "\f41b";
}
.iconsax-setting-2:before {
    content: "\f41c";
}
.iconsax-setting-3:before {
    content: "\f41d";
}
.iconsax-setting-4:before {
    content: "\f41e";
}
.iconsax-setting-5:before {
    content: "\f41f";
}
.iconsax-setting:before {
    content: "\f420";
}
.iconsax-settings:before {
    content: "\f421";
}
.iconsax-shapes-1:before {
    content: "\f422";
}
.iconsax-shapes:before {
    content: "\f423";
}
.iconsax-share:before {
    content: "\f424";
}
.iconsax-shield-cross:before {
    content: "\f425";
}
.iconsax-shield-search:before {
    content: "\f426";
}
.iconsax-shield-slash:before {
    content: "\f427";
}
.iconsax-shield-tick:before {
    content: "\f428";
}
.iconsax-shield:before {
    content: "\f429";
}
.iconsax-ship:before {
    content: "\f42a";
}
.iconsax-shop-add:before {
    content: "\f42b";
}
.iconsax-shop-remove:before {
    content: "\f42c";
}
.iconsax-shop:before {
    content: "\f42d";
}
.iconsax-shopping-bag:before {
    content: "\f42e";
}
.iconsax-shopping-cart:before {
    content: "\f42f";
}
.iconsax-shuffle:before {
    content: "\f430";
}
.iconsax-siacoin-sc:before {
    content: "\f431";
}
.iconsax-sidebar-bottom:before {
    content: "\f432";
}
.iconsax-sidebar-left:before {
    content: "\f433";
}
.iconsax-sidebar-right:before {
    content: "\f434";
}
.iconsax-sidebar-top:before {
    content: "\f435";
}
.iconsax-signpost:before {
    content: "\f436";
}
.iconsax-simcard-1:before {
    content: "\f437";
}
.iconsax-simcard-2:before {
    content: "\f438";
}
.iconsax-simcard:before {
    content: "\f439";
}
.iconsax-size:before {
    content: "\f43a";
}
.iconsax-slack:before {
    content: "\f43b";
}
.iconsax-slash:before {
    content: "\f43c";
}
.iconsax-slider-horizontal-1:before {
    content: "\f43d";
}
.iconsax-slider-horizontal:before {
    content: "\f43e";
}
.iconsax-slider-vertical-1:before {
    content: "\f43f";
}
.iconsax-slider-vertical:before {
    content: "\f440";
}
.iconsax-slider:before {
    content: "\f441";
}
.iconsax-smallcaps:before {
    content: "\f442";
}
.iconsax-smart-car:before {
    content: "\f443";
}
.iconsax-smart-home:before {
    content: "\f444";
}
.iconsax-smileys:before {
    content: "\f445";
}
.iconsax-sms-edit:before {
    content: "\f446";
}
.iconsax-sms-notification:before {
    content: "\f447";
}
.iconsax-sms-search:before {
    content: "\f448";
}
.iconsax-sms-star:before {
    content: "\f449";
}
.iconsax-sms-tracking:before {
    content: "\f44a";
}
.iconsax-sms:before {
    content: "\f44b";
}
.iconsax-snapchat:before {
    content: "\f44c";
}
.iconsax-solana-sol:before {
    content: "\f44d";
}
.iconsax-sort:before {
    content: "\f44e";
}
.iconsax-sound:before {
    content: "\f44f";
}
.iconsax-speaker:before {
    content: "\f450";
}
.iconsax-speedometer:before {
    content: "\f451";
}
.iconsax-spotify:before {
    content: "\f452";
}
.iconsax-stacks-stx:before {
    content: "\f453";
}
.iconsax-star-1:before {
    content: "\f454";
}
.iconsax-star-slash:before {
    content: "\f455";
}
.iconsax-star:before {
    content: "\f456";
}
.iconsax-status-up:before {
    content: "\f457";
}
.iconsax-status:before {
    content: "\f458";
}
.iconsax-stellar-xlm:before {
    content: "\f459";
}
.iconsax-sticker:before {
    content: "\f45a";
}
.iconsax-stickynote:before {
    content: "\f45b";
}
.iconsax-stop-circle:before {
    content: "\f45c";
}
.iconsax-stop:before {
    content: "\f45d";
}
.iconsax-story:before {
    content: "\f45e";
}
.iconsax-strongbox-2:before {
    content: "\f45f";
}
.iconsax-strongbox:before {
    content: "\f460";
}
.iconsax-subtitle:before {
    content: "\f461";
}
.iconsax-sun-1:before {
    content: "\f462";
}
.iconsax-sun-fog:before {
    content: "\f463";
}
.iconsax-sun:before {
    content: "\f464";
}
.iconsax-tag-2:before {
    content: "\f465";
}
.iconsax-tag-cross:before {
    content: "\f466";
}
.iconsax-tag-right:before {
    content: "\f467";
}
.iconsax-tag-user:before {
    content: "\f468";
}
.iconsax-tag:before {
    content: "\f469";
}
.iconsax-task-square:before {
    content: "\f46a";
}
.iconsax-task:before {
    content: "\f46b";
}
.iconsax-teacher:before {
    content: "\f46c";
}
.iconsax-tenx-pay:before {
    content: "\f46d";
}
.iconsax-tether-usdt:before {
    content: "\f46e";
}
.iconsax-text-block:before {
    content: "\f46f";
}
.iconsax-text-bold:before {
    content: "\f470";
}
.iconsax-text-italic:before {
    content: "\f471";
}
.iconsax-text-underline:before {
    content: "\f472";
}
.iconsax-text:before {
    content: "\f473";
}
.iconsax-textalign-center:before {
    content: "\f474";
}
.iconsax-textalign-justifycenter:before {
    content: "\f475";
}
.iconsax-textalign-justifyleft:before {
    content: "\f476";
}
.iconsax-textalign-justifyright:before {
    content: "\f477";
}
.iconsax-textalign-left:before {
    content: "\f478";
}
.iconsax-textalign-right:before {
    content: "\f479";
}
.iconsax-the-graph-grt:before {
    content: "\f47a";
}
.iconsax-theta-theta:before {
    content: "\f47b";
}
.iconsax-thorchain-rune:before {
    content: "\f47c";
}
.iconsax-tick-circle:before {
    content: "\f47d";
}
.iconsax-tick-square:before {
    content: "\f47e";
}
.iconsax-ticket-2:before {
    content: "\f47f";
}
.iconsax-ticket-discount:before {
    content: "\f480";
}
.iconsax-ticket-expired:before {
    content: "\f481";
}
.iconsax-ticket-star:before {
    content: "\f482";
}
.iconsax-ticket:before {
    content: "\f483";
}
.iconsax-timer-1:before {
    content: "\f484";
}
.iconsax-timer-pause:before {
    content: "\f485";
}
.iconsax-timer-start:before {
    content: "\f486";
}
.iconsax-timer:before {
    content: "\f487";
}
.iconsax-toggle-off-circle:before {
    content: "\f488";
}
.iconsax-toggle-off:before {
    content: "\f489";
}
.iconsax-toggle-on-circle:before {
    content: "\f48a";
}
.iconsax-toggle-on:before {
    content: "\f48b";
}
.iconsax-trade:before {
    content: "\f48c";
}
.iconsax-transaction-minus:before {
    content: "\f48d";
}
.iconsax-translate:before {
    content: "\f48e";
}
.iconsax-trash:before {
    content: "\f48f";
}
.iconsax-tree:before {
    content: "\f490";
}
.iconsax-trello:before {
    content: "\f491";
}
.iconsax-trend-down:before {
    content: "\f492";
}
.iconsax-trend-up:before {
    content: "\f493";
}
.iconsax-triangle-1:before {
    content: "\f494";
}
.iconsax-triangle:before {
    content: "\f495";
}
.iconsax-trontron-trx:before {
    content: "\f496";
}
.iconsax-truck-fast:before {
    content: "\f497";
}
.iconsax-truck-remove:before {
    content: "\f498";
}
.iconsax-truck-tick:before {
    content: "\f499";
}
.iconsax-truck-time:before {
    content: "\f49a";
}
.iconsax-truck:before {
    content: "\f49b";
}
.iconsax-trush-square:before {
    content: "\f49c";
}
.iconsax-twitch:before {
    content: "\f49d";
}
.iconsax-ui8:before {
    content: "\f49e";
}
.iconsax-undo:before {
    content: "\f49f";
}
.iconsax-unlimited:before {
    content: "\f4a0";
}
.iconsax-unlock:before {
    content: "\f4a1";
}
.iconsax-usd-coin-usdc:before {
    content: "\f4a2";
}
.iconsax-user-add:before {
    content: "\f4a3";
}
.iconsax-user-cirlce-add:before {
    content: "\f4a4";
}
.iconsax-user-edit:before {
    content: "\f4a5";
}
.iconsax-user-minus:before {
    content: "\f4a6";
}
.iconsax-user-octagon:before {
    content: "\f4a7";
}
.iconsax-user-remove:before {
    content: "\f4a8";
}
.iconsax-user-search:before {
    content: "\f4a9";
}
.iconsax-user-square:before {
    content: "\f4aa";
}
.iconsax-user-tag:before {
    content: "\f4ab";
}
.iconsax-user-tick:before {
    content: "\f4ac";
}
.iconsax-user:before {
    content: "\f4ad";
}
.iconsax-velas-vlx:before {
    content: "\f4ae";
}
.iconsax-verify:before {
    content: "\f4af";
}
.iconsax-vibe-vibe:before {
    content: "\f4b0";
}
.iconsax-video-add:before {
    content: "\f4b1";
}
.iconsax-video-circle:before {
    content: "\f4b2";
}
.iconsax-video-horizontal:before {
    content: "\f4b3";
}
.iconsax-video-octagon:before {
    content: "\f4b4";
}
.iconsax-video-play:before {
    content: "\f4b5";
}
.iconsax-video-remove:before {
    content: "\f4b6";
}
.iconsax-video-slash:before {
    content: "\f4b7";
}
.iconsax-video-square:before {
    content: "\f4b8";
}
.iconsax-video-tick:before {
    content: "\f4b9";
}
.iconsax-video-time:before {
    content: "\f4ba";
}
.iconsax-video-vertical:before {
    content: "\f4bb";
}
.iconsax-video:before {
    content: "\f4bc";
}
.iconsax-voice-cricle:before {
    content: "\f4bd";
}
.iconsax-voice-square:before {
    content: "\f4be";
}
.iconsax-volume-cross:before {
    content: "\f4bf";
}
.iconsax-volume-high:before {
    content: "\f4c0";
}
.iconsax-volume-low-1:before {
    content: "\f4c1";
}
.iconsax-volume-low:before {
    content: "\f4c2";
}
.iconsax-volume-mute:before {
    content: "\f4c3";
}
.iconsax-volume-slash:before {
    content: "\f4c4";
}
.iconsax-volume-up:before {
    content: "\f4c5";
}
.iconsax-vuesax:before {
    content: "\f4c6";
}
.iconsax-wallet-1:before {
    content: "\f4c7";
}
.iconsax-wallet-2:before {
    content: "\f4c8";
}
.iconsax-wallet-3:before {
    content: "\f4c9";
}
.iconsax-wallet-add-1:before {
    content: "\f4ca";
}
.iconsax-wallet-add:before {
    content: "\f4cb";
}
.iconsax-wallet-check:before {
    content: "\f4cc";
}
.iconsax-wallet-minus:before {
    content: "\f4cd";
}
.iconsax-wallet-money:before {
    content: "\f4ce";
}
.iconsax-wallet-remove:before {
    content: "\f4cf";
}
.iconsax-wallet-search:before {
    content: "\f4d0";
}
.iconsax-wallet:before {
    content: "\f4d1";
}
.iconsax-wanchain-wan-1:before {
    content: "\f4d2";
}
.iconsax-wanchain-wan:before {
    content: "\f4d3";
}
.iconsax-warning-2:before {
    content: "\f4d4";
}
.iconsax-watch-status:before {
    content: "\f4d5";
}
.iconsax-watch:before {
    content: "\f4d6";
}
.iconsax-weight-1:before {
    content: "\f4d7";
}
.iconsax-weight:before {
    content: "\f4d8";
}
.iconsax-whatsapp:before {
    content: "\f4d9";
}
.iconsax-wifi-square:before {
    content: "\f4da";
}
.iconsax-wifi:before {
    content: "\f4db";
}
.iconsax-wind-2:before {
    content: "\f4dc";
}
.iconsax-wind:before {
    content: "\f4dd";
}
.iconsax-windows:before {
    content: "\f4de";
}
.iconsax-wing-wing:before {
    content: "\f4df";
}
.iconsax-woman:before {
    content: "\f4e0";
}
.iconsax-xd:before {
    content: "\f4e1";
}
.iconsax-xiaomi:before {
    content: "\f4e2";
}
.iconsax-xrp-xrp:before {
    content: "\f4e3";
}
.iconsax-youtube:before {
    content: "\f4e4";
}
.iconsax-zel-zel:before {
    content: "\f4e5";
}
.iconsax-zoom:before {
    content: "\f4e6";
}
