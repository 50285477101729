@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.simple-list-settings-scrollspy {
    gap: 8px;
    @include media-breakpoint-down(lg) {
        display: none !important;
    }
    .nav-link-setting {
        position: relative;
        text-align: right;
        font-size: $size-13;
        font-weight: $medium;
        color: var(--gray-700);
        padding: 10px 16px;
        border-radius: $radius-10;
        border: 0;
        display: flex;
        align-items: center;
        .badge {
            margin-right: 6px;
        }
        &::before {
            content: "";
            background-color: rgba($gray-200, 0.65);
            height: 40px;
            border-radius: $radius-10;
            width: 100%;
            position: absolute;
            z-index: -1;
            right: 0;
            transform: scale(0.85);
            opacity: 0;
            transition: 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
            box-shadow: inset 0 -1px 0 0 rgba($dark, 0.015), inset 0 1px 0 0 rgba($dark, 0.015) !important;
        }
        &.active {
            color: var(--dark);
            &::before {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
}

.sidenav-profile {
    grid-area: sidenav;
    padding-top: 46px;
    border-left: 1px solid var(--gray-200) !important;
    position: fixed;
    height: 100vh;
    right: 0;
    width: 270px;
    background-color: var(--white);

    .hero-username {
        text-align: center;
        .user-image {
            width: 80px;
            height: 80px;
            border-radius: $radius-circle !important;
            object-fit: cover;
            margin-bottom: 16px;
        }
        .display-text-name {
            font-family: "Mukta", sans-serif;
            margin-bottom: 16px;
            h2 {
                font-size: $size-16;
                font-weight: $medium;
                color: var(--dark);
                margin: 0;
                text-transform: capitalize;
            }
            p {
                font-size: $size-13;
                color: var(--gray-600);
                margin: 0;
                margin-top: 4px;
                text-transform: capitalize;
            }
        }
        .edit-data {
            color: var(--dark);
            border: 1px solid var(--gray-200);
            border-radius: $radius-10;
            @include shadow-default;
            &:hover {
                color: var(--primary);
            }
        }
        .social-apps {
            margin-top: 24px;
            display: flex;
            gap: 10px;
            justify-content: center;
        }
    }
    .tab-profile {
        margin-top: 24px;
    }
    .list-details {
        list-style: none;
        padding: 20px 24px;
        margin: 0;
        .item-text {
            padding: 0;
            margin-bottom: 16px;
            &:last-child {
                margin-bottom: 00;
            }
            span {
                font-size: $size-12;
                color: var(--gray-700);
                margin-bottom: 4px;
                display: block;
            }
            .name {
                font-size: $size-13;
                font-weight: $medium;
                color: var(--dark);
                margin: 0;
            }
            .email {
                line-height: 20px;
            }
            .en {
                @include font-en;
            }
            .btn-copy {
                padding: 4px;
                border: 0;
                margin: 0;
                display: inline-flex;
                i {
                    color: var(--gray-500);
                    font-size: 16px;
                    transform: scale(-1, 1);
                    @include transition-color;
                }
                &:focus {
                    i {
                        color: var(--dark);
                    }
                }
            }
        }
    }
    .group-team-link {
        margin-top: 12px;
    }
    .content-fitst-screen {
        height: calc(100vh - 370px);
        overflow: auto;
    }
    .btn_close {
        position: absolute;
        left: 24px;
        top: 20px;
        @include media-breakpoint-up(xl) {
            display: none;
        }
    }
    .btn_logout {
        font-size: $size-13;
        font-weight: $medium;
        color: var(--red);
        position: absolute;
        top: 20px;
        right: 24px;
        border: 0 !important;
        padding-right: 0;
    }
}

.group-team-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .profile-em {
        position: relative;
        overflow: hidden;
        border: 2px solid var(--white);
        width: 28px;
        height: 28px;
        border-radius: $radius-circle;
        padding: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 0 -4px;
        transition: .4s cubic-bezier(0.79,0.14,0.15,0.86);
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: $radius-circle;
            object-position: center;
        }
    }
    .letters {
        .text {
            display: flex;
            align-items: center;
            gap: 1px;
            span {
                font-size: $size-11 !important;
                font-weight: $semiBold;
                color: $white !important;
                font-family: "Mukta", sans-serif;
                display: inline-flex;
                justify-content: center;
                margin: 0 !important;
                text-transform: uppercase;
            }
        }
    }
    .bg-blue {
        background-color: $blue;
    }
    .bg-yellow {
        background-color: $yellow;
    }
    .bg-orange {
        background-color: $orange;
    }
    &:hover {
        .profile-em {
            margin: 0 -3px;
        }
    }
}

.list-social-networks {
    padding: 6px 24px 20px 24px;
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    h3 {
        position: relative;
        font-size: $size-11;
        color: var(--gray-600);
        font-weight: $medium;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 16px;
        &::after {
            content: "";
            width: 56%;
            height: 1px;
            background-color: var(--gray-200);
        }
    }
    li {
        padding: 0;
        .app-link {
            padding: 8px 0;
            display: flex;
            align-items: center;
            gap: 12px;
            text-decoration: none;
            @include font-en;
            img {
                width: 22px;
                height: 22px;
            }
            span {
                font-size: $size-13;
                color: var(--dark);
                font-weight: $medium;
            }
        }
    }
}

.list-worktime {
    list-style: none;
    padding: 0;
    margin-top: 10px;
    li {
        padding: 14px 24px;
        display: flex;
        justify-content: space-between;
        &:nth-child(even) {
            background-color: var(--light);
        }
        span {
            font-size: $size-12;
            font-weight: $medium;
            &.day {
                color: var(--gray-700);
            }
            &.time {
                color: var(--dark);
            }
        }
    }
}



.canvas-userProfile {
    width: 270px !important;
    .btn_close {
        display: inline-flex !important;
    }
    .status-user {
        padding: 6px 12px;
        border-radius: $radius-pill;
        font-size: $size-12;
        font-weight: $medium;
        display: inline-block;
        &.online {
            background-color: rgba($green, .1);
            color: var(--green);
        }
        &.out {
            background-color: var(--light);
            color: var(--gray-700);
        }
    }
    &.offline {
        .list-details {
            .item-text {
                .email {
                    text-decoration: line-through;
                }
            }
        }
    }
}