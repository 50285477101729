@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.tab-profile {
    border-bottom: 1px solid var(--gray-200);
    box-shadow: 0 36px 18px 0 rgba($dark, .04);
    justify-content: space-evenly;
    padding: 0;
    .nav-item {
        padding: 0;
        .nav-link {
            position: relative;
            font-size: $size-13;
            font-weight: $medium;
            color: var(--gray-700);
            height: 54px;
            background-color: transparent;
            border-radius: 0;
            display: inline-flex;
            justify-content: center;
            text-align: center;
            align-items: center;
            padding: 0;
            @include transition-all;
            &::before {
                content: "";
                position: absolute;
                width: 22px;
                height: 2px;
                background-color: var(--primary);
                bottom: 0;
                opacity: 0;
            }
            &.active {
                color: var(--primary);
                &::before {
                    opacity: 1;
                }
            }
        }
    }
}