@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

$alert-padding-x: 24px;
$alert-padding-y: 20px;
$alert-border-radius: 10px;
.alert {
    // scss-docs-start alert-css-vars

    --#{$prefix}alert-padding-x: #{$alert-padding-x};
    --#{$prefix}alert-padding-y: #{$alert-padding-y};
    --#{$prefix}alert-margin-bottom: #{$alert-margin-bottom};
    --#{$prefix}alert-color: inherit;
    --#{$prefix}alert-border-color: transparent;

    --#{$prefix}alert-border-radius: #{$alert-border-radius};
    // scss-docs-end alert-css-vars

    font-size: $size-16;
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    gap: 10px;
    i {
        font-size: 20px;
    }
    &.fill {
        color: $white;
        i {
            color: $white;
        }
    }
}

.alert-primary {
    background-color: #efebfd;
    color: var(--primary);
    &.fill {
        background-color: var(--primary);
    }
    a,
    i {
        color: var(--primary);
    }
}
.alert-secondary {
    background-color: #ffeada;
    color: #cb7232;
    &.fill {
        background-color: var(--secondary);
    }
    a,
    i {
        color: #cb7232;
    }
}
.alert-success {
    background-color: #e5f1e2;
    color: #609656;
    &.fill {
        background-color: var(--green);
    }
    a,
    i {
        color: #609656;
    }
}
.alert-danger {
    background-color: #ffe8e8;
    color: #f84747;
    &.fill {
        background-color: var(--red);
    }
    a,
    i {
        color: #f84747;
    }
}
.alert-warning {
    background-color: #feecca;
    color: #ad8232;
    &.fill {
        background-color: var(--yellow);
    }
    a,
    i {
        color: #ad8232;
    }
}
.alert-info {
    background-color: #eaedff;
    color: #6c7dff;
    &.fill {
        background-color: var(--blue);
    }
    a,
    i {
        color: #6c7dff;
    }
}
.alert-dark {
    background-color: #ededee;
    color: var(--dark);
    &.fill {
        background-color: var(--dark);
    }
    a {
        color: var(--primary);
    }
    i {
        color: var(--dark);
    }
}

.alert-update {
    background: linear-gradient(to right, $primary, $purple);
    border-radius: $radius-10;
    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: space-between;
    }
    align-items: center;
    padding: 18px 20px;
    border: 0;
    margin-bottom: 30px;
    .body-alert {
        display: flex;
        align-items: center;
        gap: 16px;
        .icon-flash {
            background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.4176 9.82665H13.5851V3.22665C13.5851 1.68665 12.751 1.37499 11.7335 2.52999L11.0001 3.36415L4.7943 10.4225C3.9418 11.385 4.2993 12.1733 5.58263 12.1733H8.41513V18.7733C8.41513 20.3133 9.2493 20.625 10.2668 19.47L11.0001 18.6358L17.206 11.5775C18.0585 10.615 17.701 9.82665 16.4176 9.82665Z' fill='white'/%3E%3C/svg%3E%0A");
            background-size: 22px;
            background-repeat: no-repeat;
            width: 22px;
            height: 22px;
        }
        h2 {
            font-size: $size-14;
            color: $white;
            font-weight: $medium;
            margin-bottom: 4px;
        }
        p {
            font-size: $size-11;
            color: rgba($white, 0.7);
            margin: 0;
        }
    }
    .btn-actions {
        display: flex;
        align-items: center;
        gap: 16px;
        @include media-breakpoint-down(md) {
            margin-top: 12px;
        }
        .btn {
            border-radius: $radius-10;
            border: 0 !important;
            margin: 0;
        }
        .btn-clear {
            background-color: rgba($white, 0.2);
            color: $white;
        }
        .btn-update {
            background-color: $white;
            color: $dark;
            display: flex;
            align-items: center;
            gap: 4px;

            .arrow-left {
                background-image: url("data:image/svg+xml,%3Csvg width='4' height='8' viewBox='0 0 4 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.59993 2.07061C3.71967 1.95086 3.77589 1.79832 3.77589 1.64656C3.77589 1.4948 3.71967 1.34226 3.59993 1.22252C3.36754 0.990132 2.98423 0.990132 2.75184 1.22252L0.398508 3.57585C0.166122 3.80824 0.166122 4.19155 0.398508 4.42394L2.75184 6.77727C2.98423 7.00966 3.36754 7.00966 3.59993 6.77727C3.83232 6.54489 3.83232 6.16157 3.59993 5.92919L1.67064 3.9999L3.59993 2.07061Z' fill='%23162842' stroke='%23162842' stroke-width='0.2'/%3E%3C/svg%3E%0A");
                background-size: 4px;
                width: 8px;
                height: 8px;
                background-repeat: no-repeat;
            }
        }
    }
}
